import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Stack from '@mui/material/Stack'
import ImageList from '@mui/material/ImageList'
import toast from 'react-hot-toast'
import axios from 'axios'
import ImageListItem from '@mui/material/ImageListItem'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'

import Grid from '@mui/material/Grid'
import fixMembershipBackground from '../../assets/images/fixMembershipBackground.png'
import fix234Grid1 from '../../assets/images/fix234Grid1.png'
import fix234Grid2 from '../../assets/images/fix234Grid2.png'
import fix234Grid3 from '../../assets/images/fix234Grid3.png'
import fix234Grid4 from '../../assets/images/fix234Grid4.png'
import fix234Grid5 from '../../assets/images/fix234Grid5.png'
import fix234Grid6 from '../../assets/images/fix234Grid6.png'
import fix234Grid7 from '../../assets/images/fix234Grid7.png'
import workDetailsFooter1 from '../../assets/images/workDetailsFooter1.png'
import workDetailsFooter2 from '../../assets/images/workDetailsFooter2.png'
import LoadingImage from '../Loading'

// const services = [
//   'Rebranding',
//   'Brand Identity',
//   'Website',
//   'UI/UX',
//   'Mobile Apps',
//   'Business Development']

function srcset (image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`
  }
}

export default function WorkItemDetails () {
  const navigate = useNavigate()
  const { slug } = useParams()
  const [loading, setLoading] = useState()
  const [workImages, setWorkImages] = useState([])
  const [workDetails, setWorkDetails] = useState({})
  const [workImagesDesktop, setWorkImagesDesktop] = useState([])

  const getWorkDetails = useCallback(async () => {
    setLoading(true)
    // let services;

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PROD_MODE === 'test'
          ? process.env.REACT_APP_API_URL_TEST
          : process.env.REACT_APP_API_URL_LIVE}/admin/all-works/${slug}`)

      setWorkDetails({ ...data.work })
      setWorkImages(data.work.productGallery)
      setWorkImagesDesktop([{
        cols: 4,
        rows: 1,
        name: data.work.productGallery[0]
      },
      {
        cols: 4,
        rows: 1,
        name: data.work.productGallery[1]
      },
      {
        cols: 4,
        rows: 1,
        name: data.work.productGallery[2]
      },
      {
        cols: 9,
        rows: 1,
        name: data.work.productGallery[3]
      },
      {
        cols: 3,
        rows: 1,
        name: data.work.productGallery[4]
      },
      {
        cols: 6,
        rows: 2,
        name: data.work.productGallery[5]
      },
      {
        cols: 6,
        rows: 1,
        name: data.work.productGallery[6]
      },
      {
        cols: 6,
        rows: 1,
        name: data.work.productGallery[7]
      }])
    } catch (error) {
      toast.error(error.response.data.message, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    }
    setLoading(false)
  }, [slug])

  useEffect(() => {
    getWorkDetails()
  }, [getWorkDetails])

  const imagesMobile = [
    { img: fix234Grid1, cols: 4 },
    { img: fix234Grid2, cols: 4 },
    { img: fix234Grid3, cols: 4 },
    { img: fix234Grid4, cols: 4 },
    { img: fix234Grid5, cols: 4 },
    { img: fix234Grid6, cols: 4 },
    { img: fix234Grid7, cols: 4 }
  ]

  const imagesDesktop = [
    { img: fix234Grid1, cols: 4 },
    { img: fix234Grid2, cols: 4 },
    { img: fix234Grid3, cols: 4 },
    { img: fix234Grid4, cols: 9 },
    { img: fix234Grid5, cols: 3 },
    { img: fix234Grid6, cols: 6, rows: 2 },
    { img: fix234Grid7, cols: 6, rows: 1 }
  ]

  return (
    <Box
      sx={{
        paddingTop: { xs: '70px', md: '70px' }
      }}
    >
      {loading &&
        <LoadingImage />}
      {!loading &&
        <>
          <Box
            sx={{
              position: 'relative'

            }}
          >
            <img alt='' src={workDetails?.mainThumbnail} width='100%' height='100%' />
            <Container maxWidth='lg'>
              <Box
                sx={{
                  position: 'absolute',
                  top: '10%'
                }}
              >
                <Box
                  sx={{
                    color: 'white'
                  }}
                >
                  <Box
                    onClick={() => navigate(-1)}
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      color: 'white'
                    }}
                  >
                    <ArrowBackIcon
                      sx={{
                        fontSize: { xs: '18px', md: '25px' },
                        paddingTop: { xs: '5px', md: '10px' }
                      }}
                    />
                    <Box
                      sx={{ fontSize: { xs: '15px', md: '24px' } }}
                    >
                      Back
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '10%'
                }}
              >
                <Box
                  sx={{
                    color: 'white',
                    fontWeight: 600
                  }}
                >
                  <Box
                    sx={{ fontSize: { xs: '15px', md: '30px' } }}
                  >
                    Fix234
                  </Box>
                  <Box
                    sx={{ fontSize: { xs: '19px', md: '40px' } }}
                  >
                    {workDetails?.tagline}
                    {/* Revitalizing <br />
                    facility management */}
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
          <Container maxWidth='lg'>
            <Box
              sx={{ paddingTop: { xs: '70px', md: '200px' } }}
            >
              <Box
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: '40px'
                    }}
                  >Overview
                  </Box>
                  <Box sx={{
                    width: '50%',
                    fontSize: '20px'
                  }}
                  >
                    <Box>{workDetails?.overview}
                    </Box>
                    <Box
                      sx={{
                        paddingTop: '10px'
                      }}
                    >
                      <Box
                        onClick={() => navigate(-1)}
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          color: 'primary.main'
                        }}
                      >
                        <Box
                          sx={{ fontSize: { xs: '15px', md: '24px' } }}
                        >
                          Get in touch
                        </Box>
                        <ArrowForwardIcon
                          sx={{
                            fontSize: '18px',
                            alignSelf: 'center'
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: '20px'
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: '40px'
                    }}
                  >Services
                  </Box>
                  <Box
                    sx={{
                      width: '50%',
                      fontSize: '20px'
                    }}
                  >  <Box component='ul'>
                    {workDetails?.services?.map((service, index) => (
                      <Box
                        component='li'
                        key={index}
                      >{service}
                      </Box>
                    ))}
                  </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 600,
                    fontSize: '30px'
                  }}
                >Overview
                </Box>
                <Box
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  {workDetails?.overview}
                </Box>
              </Box>
              <Box
                sx={{
                  paddingTop: '50px',
                  display: { xs: 'block', md: 'none' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 600,
                    fontSize: '30px'
                  }}
                >Services
                </Box>
                <Box>
                  <Box component='ul'>
                    {workDetails?.services?.map((service, index) => (
                      <Box
                        component='li'
                        key={index}
                      >{service}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', md: 'block' },
                  paddingTop: '50px'
                }}
              >
                <ImageList
           // sx={{ width: 500, height: 450 }}
                  variant='quilted'
                  cols={12}
           // rowHeight={121}
                  gap={12}
                >
                  {workImagesDesktop?.map((item, index) => (
                    <ImageListItem key={index} cols={item?.cols || 1} rows={item?.rows || 1}>
                      <img
                        src={item.name}
                        alt=''
                        width='100%'
                        height='100%'
                      />
                    </ImageListItem>
                  ))}
                  {/* {workDetails?.productGallery?.map((item) => (
                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                      <img
                        {...srcset(item.img, 121, item.rows, item.cols)}
                        alt={item.title}
                        loading='lazy'
                      />
                    </ImageListItem>
                  ))} */}
                </ImageList>
              </Box>
              <Box
                sx={{
                  display: { xs: 'block', md: 'none' },
                  paddingTop: '50px'
                }}
              >
                <ImageList
                  sx={{ width: '100%' }}
                  variant='quilted'
                  cols={12}
                  gap={12}
                >
                  {workImages?.map((item, index) => (
                    <ImageListItem key={index} cols={item.cols || 12} rows={item.rows || 1}>
                      <img
                        src={item}
                        alt=''
                        width='100%'
                        height='100%'
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            </Box>
            <Box
              sx={{ paddingTop: '60px' }}
            >
              <Grid
                justifyContent='space-between'
                spacing={2} container
              >
                <Grid
                  item xs={12} md={5}
                >
                  <Stack
                    spacing={2}
                  >
                    <Box>
                      <img alt='' src={workDetailsFooter1} width='100%' height='100%' />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Box sx={{ fontWeight: 600 }}>Lidstores</Box>
                      <Box sx={{ fontWeight: 600 }}>
                        <Box
                          onClick={() => navigate(-1)}
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            color: 'primary.main'
                          }}
                        >
                          <Box
                            sx={{ fontSize: { xs: '15px', md: '24px' } }}
                          >
                            Explore next
                          </Box>
                          <ArrowForwardIcon
                            sx={{
                              fontSize: { xs: '18px', md: '30px' },
                              paddingTop: { xs: '3px', md: '6px' }
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item xs={12} md={6}
                >
                  <Box
                    sx={{
                      position: 'relative'
                    }}
                  >
                    <img alt='' src={workDetailsFooter2} width='100%' height='100%' />
                    <Box
                      sx={{
                        position: 'absolute',
                        fontWeight: 600,
                        fontSize: '20px',
                        lineHeight: '30px',
                        top: '35%',
                        left: '20%'
                      }}
                    >
                      <Box> To find out more about <br /> our work</Box>
                      <Box
                        sx={{
                          paddingTop: '10px'
                        }}
                      >
                        <Box
                          component='a'
                          href={workDetails.projectLink}
                          target='__blank'
                      // onClick={() => navigate(-1)}
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            color: 'primary.main'
                          }}
                        >
                          <Box
                            sx={{ fontSize: { xs: '15px', md: '24px' } }}
                          >
                            Get in touch
                          </Box>
                          <ArrowForwardIcon
                            sx={{
                              fontSize: '25px',
                              paddingTop: '6px'
                              // alignSelf: 'center'
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </>}
    </Box>
  )
}
