import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import logo from '../../assets/images/logo.svg'
import circleIcon from '../../assets/icons/circleIcon.svg'
import MobileNavbar from './MobileNavbar'
import DesktopNavbar from './DesktopNavbar'

export default function Navbar () {
  const [openDrawer, setOpenDrawer] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleOpenDrawer = () => setOpenDrawer(!openDrawer)

  return (
    <Box
      sx={{ display: pathname.split('/').includes('admin') ? 'none' : 'block' }}
    >
      {/* <Container
        maxWidth='lg'
      > */}
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: 'white',
          paddingTop: { xs: '10px' },
          paddingBottom: { xs: '10px' }
        }}
        position='fixed'
      >
        <Toolbar>
          <Container
            sx={{ display: 'flex' }}
            maxWidth='lg'
          >

            {/* <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '10px',
                paddingBottom: '10px'
              }}
            > */}

            <Box
              onClick={() => navigate('/')}
              sx={{
                display: { xs: 'flex', md: 'none' },
                cursor: 'pointer',
                flexGrow: 1
              }}
            >
              <img alt='' src={logo} width='50%' height='100%' />
            </Box>
            <Box
              onClick={() => navigate('/')}
              sx={{
                display: { xs: 'none', md: 'flex' },
                cursor: 'pointer',
                flexGrow: 1
              }}
            >
              <img alt='' src={logo} width='20%' height='100%' />
            </Box>
            <Box
              onClick={handleOpenDrawer}
              sx={{
                display: 'flex',
                cursor: 'pointer'
              }}
            >
              <Box
                sx={{
                  alignSelf: 'center',
                  paddingRight: '10px',
                  color: 'black'
                }}
              >
                {pathname.split('/')[1] === undefined || pathname.split('/')[1] === '' ? 'MENU' : pathname.split('/')[1].toUpperCase()}
              </Box>
              <Box>
                <img alt='' src={circleIcon} width='100%' height='100%' />
              </Box>
            </Box>

            {/* </Box> */}
          </Container>
        </Toolbar>
      </AppBar>
      {/* </Container> */}
      <MobileNavbar
        handleOpenDrawer={handleOpenDrawer}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
      <DesktopNavbar
        handleOpenDrawer={handleOpenDrawer}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
    </Box>
  )
}
