import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { Slide } from 'react-awesome-reveal'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'

import testimonials from '../../assets/images/testimonials.png'
import fixAvatar from '../../assets/images/fixAvatar.svg'
import './styles/testimonials.css'

export default function Testimonials () {
  return (
    <Container
      maxWidth='lg'
    >
      <Box
        sx={{
          display: { md: 'flex' },
          justifyContent: { md: 'space-between' },
          paddingTop: '80px'
        }}
      >
        <Slide direction='left'>
          <Box
            sx={{
              paddingRight: { md: '70px' }
            }}
          >
            <Box>
              <img alt='' src={testimonials} width='100%' height='100%' />
            </Box>
          </Box>
        </Slide>

        <Box
          sx={{
            paddingTop: { xs: '50px', md: '0px' },
            width: { md: '500px' },
            alignSelf: 'center'
          }}
        >
          <Slide direction='right'>
            <Splide
              options={{
                rewind: true,
                arrows: false,
                autoplay: true,
                type: 'loop',
                pagination: false
              }}
            >
              <SplideSlide>
                <Box>
                  <Box
                    sx={{
                      fontWeight: 600,
                      fontSize: '20px'
                    }}
                  >
                    Great Design & Solution!
                  </Box>
                  <Box
                    sx={{
                      paddingTop: '20px',
                      color: '#666666',
                      fontSize: { xs: '12px', md: '16px' }
                    }}
                  >
                    IONEC is the friendliest and most efficient company I have ever used.
                    The whole thing takes time to introduce the product and as a result puts forward only the best opportunities that really suit you. they help
                    start to finish to create a great product identity for your company
                  </Box>
                  <Box
                    sx={{ paddingTop: '40px' }}
                  >
                    <Stack
                      direction='row'
                      spacing={2}
                    >
                      <Box>
                        <img alt='' src={fixAvatar} width='100%' height='100%' />
                      </Box>
                      <Box
                        sx={{ alignSelf: 'center' }}
                      >
                        <Box
                          sx={{ fontWeight: 600 }}
                        >
                          Shakira Bankole
                        </Box>
                        <Box
                          sx={{ color: '#666666' }}
                        >@fix234
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </SplideSlide>
            </Splide>
          </Slide>
        </Box>
      </Box>
    </Container>

  )
}
