import * as Yup from 'yup'

export const FIRST_NAME = 'firstName'
export const FIRST_NAME_LABEL = 'First Name'

export const LAST_NAME = 'lastName'
export const LAST_NAME_LABEL = 'Last Name'

export const ROLE = 'role'
export const ROLE_LABEL = 'Role'

export const PROFILE_IMG = 'profileImg'
export const PROFILE_IMG_LABEL = 'Profile Image'

export default Yup.object({

  [FIRST_NAME]: Yup.string()
    .label(FIRST_NAME_LABEL)
    .required(),
  [LAST_NAME]: Yup.string()
    .label(LAST_NAME_LABEL)
    .required(),
  [ROLE]: Yup.string()
    .label(ROLE_LABEL)
    .required(),
  [PROFILE_IMG]: Yup.string()
    .label(PROFILE_IMG_LABEL)
    .required()
})
