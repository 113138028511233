import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { FileUploader } from 'react-drag-drop-files'

import Button from '../Button'

const fileTypes = ['JPEG', 'PNG', 'GIF']

export default function UploadFile ({
  handleChange, height, width, sizeText,
  uploadedImg,
  ...props
}) {
  return (
    <FileUploader
      multiple
      handleChange={handleChange}
      name='file'
      types={fileTypes}
    >

      {uploadedImg &&
        <Box
          sx={{
            cursor: 'pointer',
            position: 'relative'
          }}
        >
          <img src={uploadedImg} alt='' width='100%' height='100%' />
          <Box
            sx={{
              position: 'absolute',
              bottom: '30px',
              right: '16px'

            }}
          >
            <Button text='Replace Image' />
          </Box>
        </Box>}

      {!uploadedImg &&
        <Box
          sx={{
            cursor: 'pointer',
            display: 'flex',
            border: '2px solid #ACDFF4',
            backgroundColor: '#F7FAFC',
            width,
            height,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box>
            <Stack
              spacing={2}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button borderRadius='0px' text='Upload Image' />
              </Box>
              <Box
                sx={{
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#878787'
                }}
              >
                Drag & drop  file to upload
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {sizeText}
              </Box>
            </Stack>
          </Box>
        </Box>}
    </FileUploader>
  )
}
