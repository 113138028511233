import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

import notFound from '../assets/images/notfound.svg'

export default function NotFound () {
  const navigate = useNavigate()

  return (
    <Container
      sx={{
        paddingTop: { xs: '50px', md: '150px' }
      }}
      maxWidth='lg'
    >
      <Box
        sx={{
          display: { md: 'flex' },
          justifyContent: { md: 'space-between' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'none' }
          }}
        >
          <img src={notFound} alt='' width='100%' height='100%' />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '30px',
            alignSelf: { md: 'center' }
          }}
        >
          <Stack
            spacing={2}
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '30px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              Page not found
            </Box>
            <Box
              sx={{
                fontWeight: 400,
                fontSize: '17px',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              The page you are looking for does not exist.
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                onClick={() => navigate('/')}
                sx={{
                  borderRadius: '0px',
                  backgroundColor: 'primary.main'
                }}
                disableElevation
                type='submit'
                variant='contained'
              >
                Go back to Home Page
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Container>
  )
}
