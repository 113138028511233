import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import toast from 'react-hot-toast'
import axios from 'axios'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect, Fragment } from 'react'

import lidPort from '../../assets/images/lidPort.png'
import Button from '../Button'
import editIcon from '../../assets/icons/editIcon.svg'
import deleteIcon from '../../assets/icons/deleteIcon.svg'
import publishIcon from '../../assets/icons/publishIcon.svg'
import LoadingImage from '../Loading'
import { ADMIN_WORK_EDIT_ROUTE } from '../../utils/routes'

const manageMenu = [{ img: editIcon, text: 'Edit' },
  { img: publishIcon, text: 'Publish' },
  { img: deleteIcon, text: 'Delete' }
]

const tableData = [
  { img: lidPort, heading: 'Lidstores', subText: 'Branding, UIUX, Web Development, Marketing', date: '17 Jan 2023', status: 'Published' },
  { img: lidPort, heading: 'Lidstores', subText: 'Branding, UIUX, Web Development, Marketing', date: '17 Jan 2023', status: 'Published' },
  { img: lidPort, heading: 'Lidstores', subText: 'Branding, UIUX, Web Development, Marketing', date: '17 Jan 2023', status: 'Published' },
  { img: lidPort, heading: 'Lidstores', subText: 'Branding, UIUX, Web Development, Marketing', date: '17 Jan 2023', status: 'Published' },
  { img: lidPort, heading: 'Lidstores', subText: 'Branding, UIUX, Web Development, Marketing', date: '17 Jan 2023', status: 'Published' }
]

const token = localStorage.getItem('adminIONECToken')

export default function BasicTable () {
  const [openMenu, setOpenMenu] = useState(null)
  const [works, setWorks] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedWork, setSelectedWork] = useState('')
  const open = Boolean(openMenu)

  const navigate = useNavigate()

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  }
  const handleClose = () => {
    setOpenMenu(null)
  }

  const publishWork = async (work, state) => {
    setLoading(true)
    let res

    const dataToSubmit = {
      projectName: work.projectName,
      tagline: work.tagline,
      mainThumbnail: work.mainThumbnail,
      smallThumbnail: work?.smallThumbnail,
      overview: work.overview,
      projectLink: work.projectLink,
      services: work.services,
      productGallery: work.productGallery
    }
    try {
      if (state) {
        res = await axios.put(
    `${process.env.REACT_APP_PROD_MODE === 'test'
      ? process.env.REACT_APP_API_URL_TEST
      : process.env.REACT_APP_API_URL_LIVE}/admin/edit-work/${selectedWork._id}`,
    { ...dataToSubmit, publish: true },
    {
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json'
      }
    }
        )
      } else {
        res = await axios.put(
          `${process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE}/admin/edit-work/${selectedWork._id}`,
          { ...dataToSubmit, publish: false },
          {
            headers: {
              'x-auth-token': token,
              'Content-Type': 'application/json'
            }
          }
        )
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_PROD_MODE === 'test'
          ? process.env.REACT_APP_API_URL_TEST
          : process.env.REACT_APP_API_URL_LIVE}/admin/all-works`)

      setWorks(data.works)

      toast.success(res.data.message, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    } catch (error) {
      console.log('this is ghost', error.response)
      toast.error(error.response.data.message, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    }
    setLoading(false)
  }

  const deleteWork = async (id) => {
    const token = localStorage.getItem('adminIONECToken')
    setLoading(true)

    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_PROD_MODE === 'test'
          ? process.env.REACT_APP_API_URL_TEST
          : process.env.REACT_APP_API_URL_LIVE}/admin/delete-work/${id}`,
        {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'application/json'
          }
        }
      )

      const getWorks = await axios.get(
        `${process.env.REACT_APP_PROD_MODE === 'test'
          ? process.env.REACT_APP_API_URL_TEST
          : process.env.REACT_APP_API_URL_LIVE}/admin/all-works`)

      setWorks(getWorks.data.works)

      toast.success(data.message, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    } catch (error) {
      toast.error(error.response.data.message, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    }
    setLoading(false)
  }

  const getWorks = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PROD_MODE === 'test'
          ? process.env.REACT_APP_API_URL_TEST
          : process.env.REACT_APP_API_URL_LIVE}/admin/all-works`)

      setWorks(data.works)

      toast.success(data.message, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    } catch (error) {
      toast.error(error.response.data.message, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    getWorks()
  }, [])

  return (
    <>

      {!loading && works?.length < 1 &&
        <Box
          sx={{
            width: '100%',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          No Work has been added yet
        </Box>}

      {loading &&
        <LoadingImage />}
      {!loading && works.length > 0 &&
        <TableContainer
          elevation={0}
          component={Paper}
        >
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600
                  }}
                >Main Thumbnail
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600
                  }}
                  align='left'
                >Title
                </TableCell>
                <TableCell
                  align='right'
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 600
                      }}
                    >
                      Date Created
                    </Box>
                    {/* <Box><ArrowDownwardIcon /></Box> */}
                  </Box>
                </TableCell>
                <TableCell
                  align='right'
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 600
                      }}
                    >Status
                    </Box>
                    {/* <Box>
                      <ArrowDownwardIcon />
                    </Box> */}
                  </Box>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {works.map((work, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell width='20%' component='th' scope='row'>
                    <Box
                      sx={{ width: '50%' }}
                    >
                      <img alt='' src={work.mainThumbnail} width='100%' height='100%' />
                    </Box>
                  </TableCell>
                  <TableCell
                    width='30%'
                    align='left'
                  >
                    <Box
                      sx={{
                        fontWeight: 600
                      }}
                    >
                      {work.projectName}
                    </Box>
                    <Box
                      sx={{ width: '100%' }}
                    >
                      {work.tagline}
                    </Box>
                  </TableCell>
                  <TableCell align='center'>{DateTime.fromISO(work.createdAt).toLocaleString(DateTime.DATETIME_MED)}</TableCell>
                  <TableCell align='center'>
                    <Button
                      borderRadius='40px'
                      text={work.publish ? 'Published' : 'Unpublished'}
                      color={work.publish ? '#2FAD5A' : 'black'}
                      backgroundColor={work.publish ? '#DEFFE9' : '#F7F7F7'}
                    />
                  </TableCell>
                  <TableCell
                    onClick={(e) => {
                      handleOpenMenu(e)
                      setSelectedWork({ ...work })
                    }}
                    sx={{
                      cursor: 'pointer'
                    }}
                    align='center'
                  ><MoreHorizIcon />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
      <Menu
        id='basic-menu'
        anchorEl={openMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          sx={{
            color: 'primary.main'
          }}
        >Manage
        </MenuItem>
        {/* {manageMenu.map(({ img, text }, index) => ( */}
        <MenuItem
          onClick={() => {
            handleClose()
            navigate(`/admin/work/edit/${selectedWork.slug}`)
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <ListItemIcon><img alt='' src={editIcon} width='60%' height='100%' /></ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            publishWork(selectedWork, !selectedWork.publish)
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <ListItemIcon><img alt='' src={publishIcon} width='60%' height='100%' /></ListItemIcon>
          <ListItemText>{selectedWork.publish ? 'Unpublish' : 'Publish'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            deleteWork(selectedWork._id)
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <ListItemIcon><img alt='' src={deleteIcon} width='60%' height='100%' /></ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        {/* ))} */}
      </Menu>
    </>
  )
}
