import * as Yup from 'yup'

export const PROJECT_NAME = 'projectName'
export const PROJECT_NAME_LABEL = 'Project Name'

export const TAGLINE = 'tagline'
export const TAGLINE_LABEL = 'Tagline'

export const MAIN_THUMBNAIL = 'mainThumbnail'
export const MAIN_THUMBNAIL_LABEL = 'Main Thumbnail'

export const OVERVIEW = 'overview'
export const OVERVIEW_LABEL = 'Overview'

export const PROJECT_LINK = 'projectLink'
export const PROJECT_LINK_LABEL = 'Project Link'

export const SERVICES_1 = 'services1'
export const SERVICES_1_LABEL = 'Services 1'

export const SERVICES_2 = 'services2'
export const SERVICES_2_LABEL = 'Services 2'

export const SERVICES_3 = 'services3'
export const SERVICES_3_LABEL = 'Services 3'

export const SERVICES_4 = 'services4'
export const SERVICES_4_LABEL = 'Services 4'

export const SERVICES_5 = 'services5'
export const SERVICES_5_LABEL = 'Services 5'

export const SERVICES_6 = 'services6'
export const SERVICES_6_LABEL = 'Services 6'

export const SERVICES_ONE = 'services'
export const SERVICES_ONE_LABEL = 'Services'

export const PHOTO_GALLERY_1 = 'photoGallery1'
export const PHOTO_GALLERY_1_LABEL = 'Photo Gallery 1'

export const PHOTO_GALLERY_2 = 'photoGallery2'
export const PHOTO_GALLERY_2_LABEL = 'Photo Gallery 2'

export const PHOTO_GALLERY_3 = 'photoGallery3'
export const PHOTO_GALLERY_3_LABEL = 'Photo Gallery 3'

export const PHOTO_GALLERY_4 = 'photoGallery4'
export const PHOTO_GALLERY_4_LABEL = 'Photo Gallery 4'

export const PHOTO_GALLERY_5 = 'photoGallery5'
export const PHOTO_GALLERY_5_LABEL = 'Photo Gallery 5'

export const PHOTO_GALLERY_6 = 'photoGallery6'
export const PHOTO_GALLERY_6_LABEL = 'Photo Gallery 6'

export const PHOTO_GALLERY_7 = 'photoGallery7'
export const PHOTO_GALLERY_7_LABEL = 'Photo Gallery 7'

export const PHOTO_GALLERY_8 = 'photoGallery8'
export const PHOTO_GALLERY_8_LABEL = 'Photo Gallery 8'

export const SMALL_THUMBNAIL = 'smallThumbnail'
export const SMALL_THUMBNAIL_LABEL = 'Small Thumbnail'

export default Yup.object({
  [SMALL_THUMBNAIL]: Yup.string()
    .label(SMALL_THUMBNAIL_LABEL)
    .notRequired(),
  [MAIN_THUMBNAIL]: Yup.string()
    .label(MAIN_THUMBNAIL_LABEL)
    .notRequired(),
  [OVERVIEW]: Yup.string()
    .label(OVERVIEW_LABEL)
    .notRequired(),
  [PROJECT_LINK]: Yup.string()
    .label(PROJECT_LINK_LABEL)
    .notRequired(),
  [PROJECT_NAME]: Yup.string()
    .label(PROJECT_NAME_LABEL)
    .notRequired(),
  [TAGLINE]: Yup.string()
    .label(TAGLINE_LABEL)
    .notRequired(),
  [SERVICES_1]: Yup.string()
    .label(SERVICES_1_LABEL)
    .notRequired(),
  [SERVICES_2]: Yup.string()
    .label(SERVICES_2_LABEL)
    .notRequired(),
  [SERVICES_3]: Yup.string()
    .label(SERVICES_3_LABEL)
    .notRequired(),
  [SERVICES_4]: Yup.string()
    .label(SERVICES_4_LABEL)
    .notRequired(),
  [SERVICES_5]: Yup.string()
    .label(SERVICES_5_LABEL)
    .notRequired(),
  [SERVICES_6]: Yup.string()
    .label(SERVICES_6_LABEL)
    .notRequired(),
  [PHOTO_GALLERY_1]: Yup.string()
    .label(PHOTO_GALLERY_1_LABEL)
    .notRequired(),
  [PHOTO_GALLERY_2]: Yup.string()
    .label(PHOTO_GALLERY_2_LABEL)
    .notRequired(),
  [PHOTO_GALLERY_3]: Yup.string()
    .label(PHOTO_GALLERY_3_LABEL)
    .notRequired(),
  [PHOTO_GALLERY_4]: Yup.string()
    .label(PHOTO_GALLERY_4_LABEL)
    .notRequired(),
  [PHOTO_GALLERY_5]: Yup.string()
    .label(PHOTO_GALLERY_5_LABEL)
    .notRequired(),
  [PHOTO_GALLERY_6]: Yup.string()
    .label(PHOTO_GALLERY_6_LABEL)
    .notRequired(),
  [PHOTO_GALLERY_7]: Yup.string()
    .label(PHOTO_GALLERY_7_LABEL)
    .notRequired(),
  [PHOTO_GALLERY_8]: Yup.string()
    .label(PHOTO_GALLERY_8_LABEL)
    .notRequired()

})
