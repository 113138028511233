import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Image from 'mui-image'
import { Zoom } from 'react-awesome-reveal'

import brandsImage from '../../assets/images/brandsImage.png'

export default function BrandsWeWorkedWith () {
  return (
    <Container maxWidth='lg'>
      <Box
        sx={{
          paddingTop: '100px',
          display: { md: 'flex' },
          justifyContent: { md: 'space-between' }
        }}
      >

        <Box
          sx={{
            alignSelf: 'center',
            paddingRight: { md: '200px' }
          }}
        >
          <Zoom>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: { xs: '30px', md: '48px' },
                lineHeight: { xs: '35px', md: '64px' }
              }}
            >
              Brands we have <br /> worked with
            </Box>
            <Box
              sx={{
                paddingTop: '20px',
                width: { md: '500px' }
              }}
            >
              We keep our clients and partners happy by combining our technical and business expertise with support to ensure companies explore the value of technology and innovation in growing their business.
            </Box>
          </Zoom>
        </Box>
        <Box
          sx={{
            paddingTop: { xs: '50px', md: '0px' }
          }}
        >
          <Zoom>
            <Image src={brandsImage} height='100%' width='100%' />
          </Zoom>
        </Box>
      </Box>
    </Container>
  )
}
