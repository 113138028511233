import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import Stack from '@mui/material/Stack'
import { useNavigate } from 'react-router-dom'

import whiteLogo from '../../assets/images/whiteLogo.svg'
import whiteCancelIcon from '../../assets/icons/whiteCancelIcon.svg'
import instaIcon from '../../assets/icons/instaIcon.svg'
import twitterIcon from '../../assets/icons/twitterIcon.svg'
import linkedinIcon from '../../assets/icons/linkedinIcon.svg'
import faceIcon from '../../assets/icons/faceIcon.svg'
import { HOME_ROUTE, WORK_ROUTE, SERVICES_ROUTE, ABOUT_ROUTE, CONTACT_ROUTE } from '../../utils/routes'

const navItems = [{ text: 'Home', link: HOME_ROUTE }, { text: 'Work', link: WORK_ROUTE }, { text: 'Services', link: SERVICES_ROUTE }, { text: 'About', link: ABOUT_ROUTE }, { text: 'Contact', link: CONTACT_ROUTE }
]

const socialsIcons = [{ icon: instaIcon, link: 'https://www.instagram.com/ionec_ltd/' }, { icon: linkedinIcon, link: 'https://www.linkedin.com/in/ionec/' }, { icon: faceIcon, link: 'https://web.facebook.com/Innovate1company?_rdc=1&_rdr' }]

export default function MobileNavbar ({
  handleOpenDrawer,
  openDrawer,
  setOpenDrawer
}) {
  const navigate = useNavigate()

  return (
    <Box>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: 'primary.main',
            color: 'white'
          }
        }}
        sx={{
          background: 'primary.main',
          display: { xs: 'flex', md: 'none' }
        }}
        anchor='right'
        open={openDrawer}
        onClose={handleOpenDrawer}
      >
        <Box
          sx={{
            width: 250
          }}
        >
          <Box
            sx={{
              paddingTop: '20px',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingBottom: '50px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box
              onClick={() => navigate('/')}
              sx={{
                cursor: 'pointer'
              }}
            >
              <img alt='' src={whiteLogo} width='70%' height='100%' />
            </Box>
            <Box
              onClick={handleOpenDrawer}
              sx={{
                width: '15%',
                cursor: 'pointer'
              }}
            >
              <img alt='' src={whiteCancelIcon} width='100%' height='100%' />
            </Box>
          </Box>
          <Box>
            {navItems.map(({ text, link }, index) => (
              <ListItemButton
                key={index}
                sx={{
                  fontWeight: 600,
                  fontSize: '25px'
                }}
                onClick={() => {
                  handleOpenDrawer()
                  navigate(link)
                }}
              >
                {text}
              </ListItemButton>
            ))}
          </Box>
          <Box
            sx={{
              paddingTop: '20px',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingBottom: '50px',
              fontSize: '12px'
            }}
          >
            <Box>
              Our offices
            </Box>
            <Box
              sx={{
                display: 'flex'
              }}
            >
              <Stack
                sx={{
                  paddingTop: '20px'
                }}
              >
                <Box>Nigeria: </Box>
                <Box
                  sx={{ paddingTop: '27px' }}
                >UK:
                </Box>
                <Box
                  sx={{ paddingTop: '27px' }}
                >Kenya:
                </Box>
              </Stack>
              <Stack
                sx={{
                  paddingTop: '20px',
                  paddingLeft: '30px'
                }}
              >
                <Box
                  component='a'
                  target='_blank'
                  href='https://goo.gl/maps/D6NzjaqQQLqmjEoS9'
                  rel='noreferrer'
                  sx={{ paddingBottom: '10px' }}
                >14A Oba Elegushi Road, Ikoyi, Lagos State.
                </Box>
                <Box
                  component='a'
                  target='_blank'
                  href='https://goo.gl/maps/HK5Upz7sWFyNMnDN7'
                  rel='noreferrer'
                  sx={{ paddingBottom: '10px' }}
                >25, Dalmeyer Road London, NW10 2XA, London.
                </Box>
                <Box
                  component='a'
                  target='_blank'
                  href='https://goo.gl/maps/H9axZ6Cofck1NKPB8'
                  rel='noreferrer'
                >7th Floor, Muthangari Drive off Waiyaki Way, Westlands Nairobi.
                </Box>
              </Stack>
            </Box>
          </Box>
          <Box sx={{
            paddingTop: '30px',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
          >
            Connect with us on
          </Box>
          <Box
            sx={{
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px'
            }}
          >
            <Stack spacing={2} direction='row'>
              {socialsIcons.map(({ icon, link }, index) =>
                <Box component='a' href={link} target='__blank' key={index}><img alt='' src={icon} width='100%' height='100%' /></Box>)}
            </Stack>
          </Box>
        </Box>
      </Drawer>
    </Box>
  )
}
