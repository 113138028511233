import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import axios from 'axios'
import toast from 'react-hot-toast'
import CircularProgress from '@mui/material/CircularProgress'
import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import logoIcon from '../../assets/icons/logoIcon.svg'
import InputField from '../InputField'
import adminLoginValidator, {
  EMAIL,
  EMAIL_LABEL,
  PASSWORD,
  PASSWORD_LABEL
} from '../../validators/adminLoginValidator'
import mail from '../../assets/icons/mail.svg'
import lock from '../../assets/icons/lock.svg'
import Button from '../Button'
import { ADMIN_WORK_ROUTE } from '../../utils/routes'

const initialValues = {
  [EMAIL]: '',
  [PASSWORD]: ''
}

export default function Login () {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem('adminIONEC') &&
      localStorage.getItem('adminIONECToken')) {
      navigate(ADMIN_WORK_ROUTE)
    }
  }, [navigate])

  return (
    <Box
      sx={{
        paddingTop: { xs: '100px', md: '120px' },
        paddingBottom: { xs: '100px', md: '120px' }
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
        maxWidth='lg'
      >
        <Box
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                width: { xs: '30%', md: '10%' }
              }}
            >
              <img alt='' src={logoIcon} width='100%' height='100%' />
            </Box>
          </Box>
          <Box
            sx={{
              paddingTop: '25px',
              fontWeight: 500,
              fontSize: '24px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            Website CMS Admin
          </Box>
          <Box
            sx={{
              paddingTop: '25px',
              fontWeight: 500,
              fontSize: '24px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                width: { xs: '90%', md: '50%' }
              }}

            >
              <Formik
                initialValues={initialValues}
                validationSchema={adminLoginValidator}
                onSubmit={async (values, { isSubmitting, resetForm }) => {
                  setLoading(true)

                  try {
                    const { data } = await axios.post(
                  `${process.env.REACT_APP_PROD_MODE === 'test'
                    ? process.env.REACT_APP_API_URL_TEST
                    : process.env.REACT_APP_API_URL_LIVE}/admin/login`,
                  { ...values }
                    )

                    toast.success(data.message, {
                      style: {
                        backgroundColor: '#1E9FD4',
                        color: 'white'
                      }
                    })

                    localStorage.setItem('adminIONEC', JSON.stringify(data.decodeDetails))
                    localStorage.setItem('adminIONECToken', data.token)

                    navigate(ADMIN_WORK_ROUTE)
                  } catch (error) {
                    toast.error(error.response.data.message, {
                      style: {
                        backgroundColor: '#1E9FD4',
                        color: 'white'
                      }
                    })
                  }
                  setLoading(false)
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleBlur,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack
                      spacing={4}
                    >
                      <InputField
                        onBlur={handleBlur}
                        onChange={handleChange}
                        icon={mail}
                        label={EMAIL_LABEL}
                        errors={errors[EMAIL]}
                        name={EMAIL}
                      />
                      <InputField
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='password'
                        icon={lock}
                        label={PASSWORD_LABEL}
                        errors={errors[PASSWORD]}
                        name={PASSWORD}
                      />
                      <Box
                        sx={{
                          width: '100%'
                        }}
                      >
                        <Button disabled={isSubmitting} borderRadius='0px' type='submit' width={{ xs: '100%' }} text={isSubmitting ? <CircularProgress /> : 'Log in'} />
                      </Box>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
