import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { useLocation } from 'react-router-dom'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

export default function BackToTop () {
  const scrollToTop = () => window.scrollTo({
    top: 100,
    left: 100,
    behavior: 'smooth'
  })

  const { pathname } = useLocation()

  return (
    <Container
      sx={{
        display: pathname.split('/').includes('admin') ? 'none' : 'block',
        paddingTop: { md: '10px' },
        paddingBottom: { md: '20px' }
      }}
      maxWidth='lg'
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer'
        }}
        onClick={scrollToTop}
      >
        <Box
          sx={{
            fontWeight: 400,
            fontSize: { xs: '15px', md: '20px' },
            display: 'flex',
            justifyContent: 'center',
            color: 'primary.main',
            paddingTop: '15px'
          }}
        >
          Back to top <ArrowUpwardIcon sx={{
          // fontSize: '15px',
          // alignSelf: 'center',
          paddingLeft: '10px',
          paddingBottom: '4px'
        }}
                      />
        </Box>
      </Box>
    </Container>
  )
}
