import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Slide, Fade } from 'react-awesome-reveal'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import heroTextImage from '../../assets/images/heroTextImage.svg'

export default function HeroText () {
  return (
    <Box
      sx={{
        paddingTop: { xs: '30px' },
        backgroundImage: `url(${heroTextImage})`,
        height: { xs: '400px', md: '500px' }
      }}
    >
      <Container
        maxWidth='lg'
      >
        <Box
          sx={{
            display: { md: 'flex' },
            justifyContent: { md: 'space-between' },
            paddingTop: { xs: '80px', md: '120px' }
          }}
        >
          <Box>
            <Fade>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  fontWeight: 900,
                  fontSize: '40px',
                  textAlign: 'center',
                  color: 'primary.main',
                  justifyContent: 'center'
                }}
              >
                We innovate
                your business
              </Box>
            </Fade>
            <Slide direction='left'>
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  fontWeight: 900,
                  fontSize: { md: '70px' },
                  textAlign: 'left',
                  color: 'primary.main',
                  lineHeight: '120%'
                }}
              >

                We innovate <br />
                your business
              </Box>
            </Slide>
          </Box>
          <Box
            sx={{
              width: { md: '60%' },
              paddingTop: { md: '15px' },
              paddingLeft: { md: '100px' },
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Slide direction='right'>
              <Box
                sx={{
                  color: 'black',
                  fontSize: { md: '25px' }
                }}
              >We innovate, create and manage business solutions thereby adding value to their customers.
              </Box>
              <Box
                sx={{
                  paddingTop: { xs: '10px', md: '0px' },
                  color: 'primary.main',
                  fontWeight: 600
                }}
              >
                About
                <ArrowForwardIcon
                  sx={{
                    fontSize: '25px',
                    color: 'primary.main',
                    alignSelf: 'center',
                    paddingTop: '13px'
                  }}
                />
              </Box>
            </Slide>
          </Box>
          <Fade>
            <Box
              sx={{
                textAlign: { xs: 'center', md: 'none' },
                paddingTop: '10px',
                display: { xs: 'block', md: 'none' }
              }}
            >
              <Box
                sx={{
                  color: 'black',
                  width: { md: '50%' },
                  fontSize: { md: '25px' }
                }}
              >We innovate, create and manage business solutions thereby adding value to their customers.
              </Box>
              <Box
                sx={{
                  paddingTop: '10px',
                  color: 'primary.main',
                  fontWeight: 600
                }}
              >
                About
                <ArrowForwardIcon
                  sx={{
                    fontSize: '25px',
                    color: 'primary.main',
                    alignSelf: 'center',
                    paddingTop: '13px'
                  }}
                />
              </Box>
            </Box>
          </Fade>
        </Box>
      </Container>
    </Box>
  )
}
