import Box from '@mui/material/Box'

import HeroText from '../components/Home/HeroText'
import heroTextImage from '../assets/images/heroTextImage.svg'
import PortfolioItem from '../components/Home/PortfolioItems'
import BrandsWeWorkedWith from '../components/Home/BrandsWeWorkedWith'
import Testimonials from '../components/Home/Testimonials'

export default function Home () {
  return (
    <Box>
      <HeroText />
      <PortfolioItem />
      <BrandsWeWorkedWith />
      <Testimonials />
    </Box>
  )
}
