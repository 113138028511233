import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import axios from 'axios'
import { useEffect, useState } from 'react'

import LoadingImage from './Loading'

const whatWeCards = [{
  heading:
  <Box
    sx={{
      fontWeight: 600,
      fontSize: '22px'
    }}
  >
    Lazy Work
  </Box>,
  subText: 'We are hard working and passionate professionals that understand the value of setting standards and making an impact that will eventually change lives.',
  backgroundColor: 'primary.main',
  borderTop: '8px solid black',
  numColor: 'white',
  headingColor: 'white',
  subTextColor: 'white'
},
{

  heading:
  <Box
    sx={{
      fontWeight: 600,
      fontSize: '22px'
    }}
  >
    Unpaid Work
  </Box>,
  subText: 'Free work is not good business for anyone. We believe that the most professional, valuable and effective work is built on the foundation of close collaboration, reciprocal partnership and deep understanding.',
  backgroundColor: 'black',
  borderTop: '8px solid #1E9FD4',
  numColor: 'white',
  headingColor: 'white',
  subTextColor: 'white'
},
{
  num: 'Three',
  heading:
  <Box
    sx={{
      fontWeight: 600,
      fontSize: '22px'
    }}
  >

    Limit Creativity
  </Box>,
  subText: 'We let employees share ideas and grow additional skills that makes them .....',
  backgroundColor: '#EDEDED',
  borderTop: '8px solid black',
  numColor: '#262525',
  headingColor: 'primary.main',
  subTextColor: '#262525'
}

]

export default function About () {
  const [team, setTeam] = useState([])
  const [loading, setLoading] = useState(false)

  const getTeam = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get(
  `${process.env.REACT_APP_PROD_MODE === 'test'
    ? process.env.REACT_APP_API_URL_TEST
    : process.env.REACT_APP_API_URL_LIVE}/admin/all-members`
      )

      setTeam(data.team)
    } catch (error) {
    }
    setLoading(false)
  }

  useEffect(() => {
    getTeam()
  }, [])

  return (
    <Container
      sx={{
        paddingTop: { xs: '100px', md: '120px' }
      }}
      maxWidth='lg'
    >
      <Box>
        <Box
          sx={{
            fontWeight: 600,
            fontSize: { xs: '30px', md: '50px' },
            color: 'primary.main',
            textAlign: { xs: 'center', md: 'left' },
            width: { md: '50%' },
            lineHeight: { md: '60px' }
          }}
        >
          Our business, <br />
          your tool
        </Box>
        <Box
          sx={{
            fontWeight: 600,
            fontSize: { xs: '25px', md: '55px' },
            textAlign: { xs: 'center' },
            width: { md: '100%' },
            paddingTop: { xs: '30px', md: '50px' },
            display: { md: 'flex' },
            justifyContent: { md: 'center' }
          }}
        >
          Our Team
        </Box>
        <Box
          sx={{
            paddingTop: { xs: '50px', md: '70px' }
          }}
        >

          {!loading && team.length < 1 && <Box
            sx={{
              width: '100%',
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
                                          >
            No Team to display
          </Box>}
          {loading &&
            <LoadingImage />}
          {!loading && team.length > 0 &&
            <Grid
              alignItems='center'
              justifyContent='center'
              container
              spacing={{ xs: 4, md: 8 }}
            >
              {team.map(({ profileImg, firstName, lastName, role }, index) => (
                <Grid
                  key={index} item xs={12} md={4}
                >
                  <Stack
                    spacing={1}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#F0F0F0',
                          borderRadius: '50%',
                          height: '330px',
                          width: '330px'
                        }}
                      >
                        <img alt='' src={profileImg} width='100%' height='100%' />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Stack
                        direction='row'
                        spacing={1}
                        sx={{
                          fontWeight: '600px'
                        }}
                      >
                        <Box
                          sx={{
                            color: 'primary.main'
                          }}
                        >{firstName}
                        </Box>
                        <Box>{lastName}</Box>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#9F9F9F'
                      }}
                    >{role}
                    </Box>
                  </Stack>
                </Grid>
              ))}
            </Grid>}
        </Box>
        <Box>
          <Box
            sx={{
              fontWeight: 600,
              paddingTop: { xs: '60px', md: '150px' },
              fontSize: { xs: '25px', md: '48px' },
              display: 'flex',
              justifyContent: 'center'
            }}
          >What we don’t do
          </Box>
          <Box
            sx={{ paddingTop: { xs: '30px', md: '60px' } }}
          >
            <Stack
              spacing={2}
              direction={{ md: 'row' }}
              justifyContent='space-between'
            >
              {
              whatWeCards.map(({
                num, heading, subText,
                backgroundColor, borderTop,
                numColor, headingColor, subTextColor
              }, index) => (
                <Box
                  key={index}
                  sx={{
                    paddingTop: { xs: '20px', md: '0px' }
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      height: '300px',
                      color: ' white',
                      borderTop,
                      padding: '20px',
                      width: { md: '350px' }
                    }}
                  >
                    <Stack
                      spacing={2}
                    >
                      {/* <Box
                        sx={{
                          color: numColor
                        }}
                      >
                        {num}
                      </Box> */}
                      <Box
                        sx={{
                          fontWeight: 600,
                          fontSize: '30px',
                          color: headingColor,
                          paddingTop: '20px'
                        }}
                      >
                        {heading}
                      </Box>
                      <Box
                        sx={{ color: subTextColor }}
                      >
                        {subText}
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              ))
        }
            </Stack>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
