import Box from '@mui/material/Box'
import axios from 'axios'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

// admin pages
import Login from './components/admin/Login'
import WorkAdmin from './components/admin/WorkAdmin'
import AddWorkAdmin from './components/admin/AddWorkAdmin'
import EditWorkAdmin from './components/admin/EditWorkAdmin'
import TeamAdmin from './components/admin/TeamAdmin'

// user pages
import Navbar from './components/Navbar'
import Home from './pages/Home'
import WorkItems from './components/Work/WorkItems'
import WorkItemDetails from './components/Work/WorkItemDetails'
import Services from './components/Services'
import About from './components/About'
import Contact from './components/Contact'
import GoToContact from './components/GoToContact'
import BackToTop from './components/BackToTop'
import NotFound from './components/NotFound'
import Footer from './components/Footer'
import ScrollToTop from './utils/ScrollToTop'
import { theme } from './utils/theme'
import {
  HOME_ROUTE, WORK_ROUTE,
  WORK_DETAILS_ROUTE,
  SERVICES_ROUTE,
  ABOUT_ROUTE,
  CONTACT_ROUTE,
  ADMIN_LOGIN_ROUTE,
  ADMIN_WORK_ROUTE,
  ADMIN_WORK_ADD_ROUTE,
  ADMIN_TEAM_ROUTE,
  ADMIN_WORK_EDIT_ROUTE,
  ADMIN_ROUTE
} from './utils/routes'
import './style.scss'

function App () {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{ fontFamily: 'Graphik' }}
        >
          <Navbar />
          <ScrollToTop>
            <Routes>
              <Route exact path={ADMIN_TEAM_ROUTE} element={<TeamAdmin />} />
              <Route exact path={ADMIN_WORK_EDIT_ROUTE} element={<EditWorkAdmin />} />
              <Route exact path={ADMIN_WORK_ADD_ROUTE} element={<AddWorkAdmin />} />
              <Route exact path={ADMIN_WORK_ROUTE} element={<WorkAdmin />} />
              <Route exact path={ADMIN_ROUTE} element={<Login />} />
              <Route exact path={ADMIN_LOGIN_ROUTE} element={<Login />} />
              <Route exact path={CONTACT_ROUTE} element={<Contact />} />
              <Route exact path={ABOUT_ROUTE} element={<About />} />
              <Route exact path={SERVICES_ROUTE} element={<Services />} />
              <Route exact path={WORK_ROUTE} element={<WorkItems />} />
              <Route exact path={WORK_DETAILS_ROUTE} element={<WorkItemDetails />} />
              <Route exact path={HOME_ROUTE} element={<Home />} />
              <Route exact path='*' element={<NotFound />} />
            </Routes>
            <GoToContact />
            <BackToTop />
            <Footer />
            <Toaster />
          </ScrollToTop>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default App
