import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Divider from '@mui/material/Divider'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'

import bin from '../../assets/icons/bin.svg'
import NavbarAdmin from './NavbarAdmin'
import UploadFile from './UploadFile'
import Button from '../Button'
import { ADMIN_WORK_ROUTE } from '../../utils/routes'
import InputField from '../InputField'
import AddWorkAdminFormValidator, {
  MAIN_THUMBNAIL,
  MAIN_THUMBNAIL_LABEL,
  OVERVIEW,
  OVERVIEW_LABEL,
  PROJECT_NAME,
  PROJECT_NAME_LABEL,
  TAGLINE,
  TAGLINE_LABEL,
  PROJECT_LINK,
  PROJECT_LINK_LABEL,
  SERVICES_1,
  SERVICES_1_LABEL,
  SERVICES_2,
  SERVICES_2_LABEL,
  SERVICES_3,
  SERVICES_3_LABEL,
  SERVICES_4,
  SERVICES_4_LABEL,
  SERVICES_5,
  SERVICES_5_LABEL,
  SERVICES_6,
  SERVICES_6_LABEL,
  SERVICES_ONE,
  SERVICES_ONE_LABEL,
  PHOTO_GALLERY_1,
  PHOTO_GALLERY_1_LABEL,
  PHOTO_GALLERY_2,
  PHOTO_GALLERY_2_LABEL,
  PHOTO_GALLERY_3,
  PHOTO_GALLERY_3_LABEL,
  PHOTO_GALLERY_4,
  PHOTO_GALLERY_4_LABEL,
  PHOTO_GALLERY_5,
  PHOTO_GALLERY_5_LABEL,
  PHOTO_GALLERY_6,
  PHOTO_GALLERY_6_LABEL,
  PHOTO_GALLERY_7,
  PHOTO_GALLERY_7_LABEL,
  PHOTO_GALLERY_8,
  PHOTO_GALLERY_8_LABEL,
  SMALL_THUMBNAIL,
  SMALL_THUMBNAIL_LABEL
} from '../../validators/AddWorkAdminFormValidator'

const fileTypes = ['JPEG', 'PNG', 'GIF']

const photoGalleryItemMobile = [{
  height: '380px',
  sizeText: '350px X 350px',
  cols: 12,
  name: PHOTO_GALLERY_1
},
{
  height: '380px',
  sizeText: '350px X 350px',
  cols: 12,
  name: PHOTO_GALLERY_2
},
{
  height: '380px',
  sizeText: '350px X 350px',
  cols: 12,
  name: PHOTO_GALLERY_3
},
{
  height: '380px',
  sizeText: '900px X 350px',
  cols: 12,
  name: PHOTO_GALLERY_4
},
{
  height: '380px',
  sizeText: '270px X 350px',
  cols: 12,
  name: PHOTO_GALLERY_5
},
{
  height: '780px',
  sizeText: '530px X 780px',
  cols: 12,
  name: PHOTO_GALLERY_6
},
{
  height: '377px',
  sizeText: '630px X 480px',
  cols: 12,
  name: PHOTO_GALLERY_7
},
{
  height: '377px',
  sizeText: '630px X 480px ',
  cols: 12,
  name: PHOTO_GALLERY_8
}
]

const photoGalleryItemDesktop = [{
  height: '380px',
  sizeText: '350px X 350px',
  cols: 4,
  rows: 1,
  name: PHOTO_GALLERY_1
},
{
  height: '380px',
  sizeText: '350px X 350px',
  cols: 4,
  rows: 1,
  name: PHOTO_GALLERY_2
},
{
  height: '380px',
  sizeText: '350px X 350px',
  cols: 4,
  rows: 1,
  name: PHOTO_GALLERY_3
},
{
  height: '380px',
  sizeText: '900px X 350px',
  cols: 9,
  rows: 1,
  name: PHOTO_GALLERY_4
},
{
  height: '380px',
  sizeText: '270px X 350px',
  cols: 3,
  rows: 1,
  name: PHOTO_GALLERY_5
},
{
  height: '780px',
  sizeText: '530px X 780px',
  cols: 6,
  rows: 2,
  name: PHOTO_GALLERY_6
},
{
  height: '377px',
  sizeText: '630px X 480px',
  cols: 6,
  rows: 1,
  name: PHOTO_GALLERY_7
},
{
  height: '377px',
  sizeText: '630px X 480px ',
  cols: 6,
  rows: 1,
  name: PHOTO_GALLERY_8
}
]

const initialValues = {
  [MAIN_THUMBNAIL]: '',
  [OVERVIEW]: '',
  [PROJECT_LINK]: '',
  [SERVICES_1]: '',
  [SERVICES_2]: '',
  [SERVICES_3]: '',
  [SERVICES_4]: '',
  [SERVICES_5]: '',
  [SERVICES_6]: '',
  [PHOTO_GALLERY_1]: '',
  [PHOTO_GALLERY_2]: '',
  [PHOTO_GALLERY_3]: '',
  [PHOTO_GALLERY_4]: '',
  [PHOTO_GALLERY_5]: '',
  [PHOTO_GALLERY_6]: '',
  [PHOTO_GALLERY_7]: '',
  [PHOTO_GALLERY_8]: '',
  [PROJECT_NAME]: '',
  [TAGLINE]: '',
  [SMALL_THUMBNAIL]: ''
}

export default function AddWorkAdmin () {
  const navigate = useNavigate()
  const [file, setFile] = useState(null)
  const [submitType, setSubmitType] = useState('')

  // const deleteWork = async () => {
  //   try {

  //   const  res = await axios.post(
  //       `${process.env.REACT_APP_PROD_MODE === 'test'
  //         ? process.env.REACT_APP_API_URL_TEST
  //         : process.env.REACT_APP_API_URL_LIVE}/admin/add-work`,
  //       { ...values, publish: true }

  //   } catch (error) {

  //   }
  // }

  return (
    <Box>
      <NavbarAdmin />
      <Container
        sx={{ paddingTop: '100px' }}
        maxWidth='lg'
      >
        <Box>
          <Stack
            spacing={2}
            direction={{ md: 'row' }}

          >
            <Box
              sx={{
                fontWeight: 600,
                color: 'primary.main',
                fontSize: '40px',
                display: { xs: 'flex' },
                justifyContent: { xs: 'center', md: 'flex-start' }
              }}
            >
              Work
            </Box>
            <Box
              onClick={() => navigate(ADMIN_WORK_ROUTE)}
              sx={{
                fontWeight: 500,
                color: 'black',
                fontSize: '20px',
                display: { xs: 'flex' },
                justifyContent: { xs: 'center' },
                alignSelf: { md: 'center' },
                cursor: 'pointer'
              }}
            >
              <ArrowBackIcon /> Back
            </Box>
          </Stack>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={AddWorkAdminFormValidator}
          onSubmit={async (values, { isSubmitting, resetForm }) => {
            let res
            const token = localStorage.getItem('adminIONECToken')

            const dataToSubmit = {
              projectName: values.projectName,
              tagline: values.tagline,
              mainThumbnail: values.mainThumbnail,
              smallThumbnail: values.smallThumbnail,
              overview: values.overview,
              projectLink: values.projectLink,
              services: [values.services1, values.services2, values.services3, values.services4, values.services5, values.services6],
              productGallery: [values.photoGallery1, values.photoGallery2, values.photoGallery3, values.photoGallery4, values.photoGallery5, values.photoGallery6, values.photoGallery7, values.photoGallery8]
            }

            try {
              if (submitType === 'publish') {
                res = await axios.post(
            `${process.env.REACT_APP_PROD_MODE === 'test'
              ? process.env.REACT_APP_API_URL_TEST
              : process.env.REACT_APP_API_URL_LIVE}/admin/add-work`,
            { ...dataToSubmit, publish: true },
            {
              headers: {
                'x-auth-token': token,
                'Content-Type': 'application/json'
              }
            }
                )
              } else {
                res = await axios.post(
                  `${process.env.REACT_APP_PROD_MODE === 'test'
                    ? process.env.REACT_APP_API_URL_TEST
                    : process.env.REACT_APP_API_URL_LIVE}/admin/add-work-save`,
                  { ...dataToSubmit, publish: false },
                  {
                    headers: {
                      'x-auth-token': token,
                      'Content-Type': 'application/json'
                    }
                  }
                )
              }
              toast.success(res.data.message)
              resetForm()
              navigate(ADMIN_WORK_ROUTE)
            } catch (error) {
              if (error.response.status === 422) {
                Object.values(error.response.data.errors).map((error, index) => (
                  toast.error(Object.values(error))
                ))
              } else (toast.error(error.response.data.message))
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleBlur,
            values,
            setFieldTouched,
            setFieldError
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  paddingTop: '50px'
                }}
              >
                <Box
                  sx={{ paddingTop: { xs: '15px', md: '30px' } }}
                >
                  <InputField
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={PROJECT_NAME_LABEL}
                    errors={errors[PROJECT_NAME]}
                    name={PROJECT_NAME}
                  />
                </Box>
                <Box
                  sx={{
                    paddingTop: { xs: '15px', md: '30px' },
                    paddingBottom: { xs: '15px', md: '30px' }
                  }}
                >
                  <InputField
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={TAGLINE_LABEL}
                    errors={errors[TAGLINE]}
                    name={TAGLINE}
                  />
                </Box>
                <Box
                  sx={{
                    paddingBottom: '10px',
                    paddingLeft: '20px',
                    fontWeight: 600
                  }}
                >
                  Main Thumbnail
                </Box>
                <Divider />
                <Box
                  sx={{ paddingTop: { xs: '10px', md: '20px' } }}
                >
                  <UploadFile
                    onTypeError={(err) =>
                      toast.error(err, {
                        style: {
                          backgroundColor: '#1E9FD4',
                          color: 'white'
                        }
                      })}
                    uploadedImg={values[MAIN_THUMBNAIL]}
                    handleChange={
                      e => {
                        if (!e.length) {
                          return setFieldValue(MAIN_THUMBNAIL, '')
                        }

                        setFieldTouched(MAIN_THUMBNAIL, true, false)

                        const file = e[0]

                        const fileSize = file.size / 1024 / 1024

                        if (fileSize > 2) {
                          toast.error('File size cannot be more than 2MB.', {
                            style: {
                              backgroundColor: '#1E9FD4',
                              color: 'white'
                            }
                          })

                          return setFieldError(MAIN_THUMBNAIL, 'File size cannot be more than 2MB.')
                        }
                        const reader = new window.FileReader()

                        reader.readAsDataURL(file)

                        reader.onload = () => {
                          setFieldValue(MAIN_THUMBNAIL, reader.result)
                        }

                        reader.onerror = () => setFieldValue(MAIN_THUMBNAIL, '')
                      }

                    }
                    height='409px'
                    width={{ xs: '100%' }}
                    sizeText='1440px X 765px'
                  />
                </Box>
                <Box
                  sx={{
                    paddingBottom: '10px',
                    paddingLeft: '20px',
                    fontWeight: 600,
                    paddingTop: '50px'
                  }}
                >
                  Small Thumbnail
                </Box>
                <Divider />
                <Box
                  sx={{
                    paddingTop: { xs: '10px', md: '20px' },
                    display: { md: 'flex' },
                    justifyContent: { md: 'center' }
                  }}
                >
                  <UploadFile
                    uploadedImg={values[SMALL_THUMBNAIL]}
                    handleChange={
                      e => {
                        if (!e.length) {
                          return setFieldValue(SMALL_THUMBNAIL, '')
                        }

                        setFieldTouched(SMALL_THUMBNAIL, true, false)

                        const file = e[0]

                        const fileSize = file.size / 1024 / 1024

                        if (fileSize > 2) {
                          e.target.value = ''

                          return setFieldError(SMALL_THUMBNAIL, 'File size cannot be more than 2MB.')
                        }
                        const reader = new window.FileReader()

                        reader.readAsDataURL(file)

                        reader.onload = () => {
                          setFieldValue(SMALL_THUMBNAIL, reader.result)
                        }

                        reader.onerror = () => setFieldValue(SMALL_THUMBNAIL, '')
                      }

                    }
                    height='409px'
                    width={{ xs: '100%', md: '400px' }}
                  />
                </Box>
              </Box>
              <Box
                sx={{ paddingTop: '70px' }}
              >
                <Box
                  sx={{
                    paddingBottom: '10px',
                    paddingLeft: '20px',
                    fontWeight: 600
                  }}
                >Overview
                </Box>
                <Divider />
                <Box
                  sx={{ paddingTop: '10px' }}
                >
                  <InputField
                    onBlur={handleBlur}
                    onChange={handleChange}
                    multiline
                    minRows={12}
                    placeholder='Leave a brief description about project'
                    // label={OVERVIEW_LABEL}
                    errors={errors[OVERVIEW]}
                    name={OVERVIEW}
                  />
                </Box>
                <Box
                  sx={{ paddingTop: { xs: '15px', md: '30px' } }}
                >
                  <InputField
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={PROJECT_LINK_LABEL}
                    errors={errors[PROJECT_LINK]}
                    name={PROJECT_LINK}
                  />
                </Box>
                <Box
                  sx={{ paddingTop: '70px' }}
                >
                  <Box
                    sx={{
                      paddingBottom: '10px',
                      paddingLeft: '20px',
                      fontWeight: 600
                    }}
                  >Services
                  </Box>
                  <Divider />
                  <Stack
                    spacing={2}
                    sx={{
                      paddingTop: '30px'
                    }}
                  >
                    <Box
                      sx={{
                        display: { md: 'flex' },
                        justifyContent: { md: 'space-between' }
                      }}
                    >
                      <Box
                        sx={{
                          alignSelf: { md: 'center' }
                        }}
                      >{SERVICES_1_LABEL}
                      </Box>
                      <InputField
                        width={{ md: '80%' }}
                        topHidden
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors[SERVICES_1]}
                        name={SERVICES_1}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { md: 'flex' },
                        justifyContent: { md: 'space-between' }
                      }}
                    >
                      <Box
                        sx={{
                          alignSelf: { md: 'center' }
                        }}
                      >{SERVICES_2_LABEL}
                      </Box>
                      <InputField
                        topHidden
                        width={{ md: '80%' }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors[SERVICES_2]}
                        name={SERVICES_2}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { md: 'flex' },
                        justifyContent: { md: 'space-between' }
                      }}
                    >
                      <Box
                        sx={{
                          alignSelf: { md: 'center' }
                        }}
                      >{SERVICES_3_LABEL}
                      </Box>
                      <InputField
                        topHidden
                        width={{ md: '80%' }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors[SERVICES_3]}
                        name={SERVICES_3}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { md: 'flex' },
                        justifyContent: { md: 'space-between' }
                      }}
                    >
                      <Box
                        sx={{
                          alignSelf: { md: 'center' }
                        }}
                      >{SERVICES_4_LABEL}
                      </Box>
                      <InputField
                        topHidden
                        width={{ md: '80%' }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors[SERVICES_4]}
                        name={SERVICES_4}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { md: 'flex' },
                        justifyContent: { md: 'space-between' }
                      }}
                    >
                      <Box
                        sx={{
                          alignSelf: { md: 'center' }
                        }}
                      >{SERVICES_5_LABEL}
                      </Box>
                      <InputField
                        topHidden
                        width={{ md: '80%' }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors[SERVICES_5]}
                        name={SERVICES_5}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { md: 'flex' },
                        justifyContent: { md: 'space-between' }
                      }}
                    >
                      <Box
                        sx={{
                          alignSelf: { md: 'center' }
                        }}
                      >{SERVICES_6_LABEL}
                      </Box>
                      <InputField
                        topHidden
                        width={{ md: '80%' }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors[SERVICES_6]}
                        name={SERVICES_6}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Box>
              <Box
                sx={{ paddingTop: '70px' }}
              >
                <Box
                  sx={{
                    paddingBottom: '10px',
                    paddingLeft: '20px',
                    fontWeight: 600
                  }}
                >Product Gallery
                </Box>
                <Divider />
                <Box
                  sx={{ paddingTop: '30px' }}
                >
                  <Box
                    sx={{ display: { xs: 'flex', md: 'none' } }}
                  >
                    <ImageList
                      sx={{ width: '100%' }}
                      variant='quilted'
                      cols={12}
                      gap={12}

                    >
                      {photoGalleryItemMobile.map(({ height, sizeText, cols, rows, name }, index) =>
                        <ImageListItem key={index} cols={cols}>
                          <UploadFile
                            uploadedImg={values[name]}
                            handleChange={
                              e => {
                                if (!e.length) {
                                  return setFieldValue(name, '')
                                }

                                setFieldTouched(name, true, false)

                                const file = e[0]

                                const fileSize = file.size / 1024 / 1024

                                if (fileSize > 2) {
                                  e.target.value = ''

                                  return setFieldError(name, 'File size cannot be more than 2MB.')
                                }
                                const reader = new window.FileReader()

                                reader.readAsDataURL(file)

                                reader.onload = () => {
                                  setFieldValue(name, reader.result)
                                }

                                reader.onerror = () => setFieldValue(name, '')
                              }

                            }
                            height={height}
                            sizeText={sizeText}
                            width={{ xs: '100%', md: '1440px' }}
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[name]}
                          </Box>
                        </ImageListItem>
                      )}
                    </ImageList>
                  </Box>
                  <Box
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                  >
                    <ImageList
                      sx={{ width: '100%' }}
                      variant='quilted'
                      cols={12}
                      gap={12}
                    >
                      {photoGalleryItemDesktop.map(({ height, sizeText, cols, rows, name }, index) =>
                        <ImageListItem key={index} cols={cols} rows={rows}>
                          <UploadFile
                            uploadedImg={values[name]}
                            handleChange={
                              e => {
                                if (!e.length) {
                                  return setFieldValue(name, '')
                                }

                                setFieldTouched(name, true, false)

                                const file = e[0]

                                const fileSize = file.size / 1024 / 1024

                                if (fileSize > 2) {
                                  e.target.value = ''

                                  return setFieldError(name, 'File size cannot be more than 2MB.')
                                }
                                const reader = new window.FileReader()

                                reader.readAsDataURL(file)

                                reader.onload = () => {
                                  setFieldValue(name, reader.result)
                                }

                                reader.onerror = () => setFieldValue(name, '')
                              }

                            }
                            height={height}
                            sizeText={sizeText}
                            width={{ xs: '100%' }}
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[name]}
                          </Box>
                        </ImageListItem>
                      )}
                    </ImageList>
                  </Box>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-end' },
                paddingTop: { xs: '60px' },
                paddingBottom: { xs: '100px' }
              }}
              >
                <Stack
                  spacing={{ xs: 4, md: 2 }}
                  direction={{ md: 'row' }}
                >
                  {/* <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center' },
                      cursor: 'pointer'
                    }}
                  >
                    <img src={bin} alt='' width='' height='' />
                  </Box> */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center' }
                    }}
                  >
                    <Button
                      type='submit'
                      disabled={isSubmitting}
                      borderRadius='0px'
                      text={isSubmitting && submitType !== 'publish' ? <CircularProgress /> : 'Save'}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center' }
                    }}
                  >
                    <Button
                      onClick={() => setSubmitType('publish')}
                      type='submit'
                      disabled={isSubmitting} borderRadius='0px' backgroundColor='#2FAD5A'
                      text={isSubmitting && submitType === 'publish' ? <CircularProgress /> : 'Publish'}
                    />
                  </Box>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  )
}
