import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import toast from 'react-hot-toast'
import CircularProgress from '@mui/material/CircularProgress'
import { FileUploader } from 'react-drag-drop-files'
import { useState, useEffect } from 'react'
import { Formik } from 'formik'

import LoadingImage from '../Loading'
import NavbarAdmin from './NavbarAdmin'
import Button from '../Button'
import adminProfilePlaceholder from '../../assets/images/adminProfilePlacholder.png'
import InputField from '../InputField'
import editIcon from '../../assets/icons/editIcon.svg'
import deleteIcon from '../../assets/icons/deleteIcon.svg'
import adminAddStaffValidator, {
  FIRST_NAME,
  FIRST_NAME_LABEL,
  LAST_NAME,
  LAST_NAME_LABEL,
  ROLE,
  ROLE_LABEL,
  PROFILE_IMG
} from '../../validators/adminAddStaffValidator'

import './styles/fileUploader.css'

const fileTypes = ['JPEG', 'PNG', 'JPG']

const initialValues = {
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [ROLE]: '',
  [PROFILE_IMG]: ''
}

const token = localStorage.getItem('adminIONECToken')

export default function TeamAdmin () {
  const [openModal, setOpenModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [allTeam, setAllTeam] = useState([])
  const [editTeamIndex, setEditTeamIndex] = useState()
  const [loading, setLoading] = useState(false)

  const handleOpenModal = () => {
    setOpenModal(!openModal)
  }

  const handleEditOpenModal = (index) => {
    setOpenEditModal(!openEditModal)
    setEditTeamIndex(index)
  }

  const initialValuesEditTeam = {
    [FIRST_NAME]: allTeam[editTeamIndex]?.firstName,
    [LAST_NAME]: allTeam[editTeamIndex]?.lastName,
    [ROLE]: allTeam[editTeamIndex]?.role,
    [PROFILE_IMG]: allTeam[editTeamIndex]?.profileImg
  }

  const deleteTeamMember = async (id) => {
    setLoading(true)

    try {
      const { data } = await axios.delete(
    `${process.env.REACT_APP_PROD_MODE === 'test'
      ? process.env.REACT_APP_API_URL_TEST
      : process.env.REACT_APP_API_URL_LIVE}/admin/delete-team-member/${id}`, {
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json'
      }
    }
      )

      const res = await axios.get(
        `${process.env.REACT_APP_PROD_MODE === 'test'
          ? process.env.REACT_APP_API_URL_TEST
          : process.env.REACT_APP_API_URL_LIVE}/admin/all-members`
      )

      setAllTeam(res.data.team)

      toast.success(data.message, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })

      getTeam()
    } catch (error) {
      toast.error(error.response.data.msg, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    }
    setLoading(false)
  }

  const getTeam = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get(
    `${process.env.REACT_APP_PROD_MODE === 'test'
      ? process.env.REACT_APP_API_URL_TEST
      : process.env.REACT_APP_API_URL_LIVE}/admin/all-members`
      )

      setAllTeam(data.team)
      toast.success(data.message, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    } catch (error) {
      toast.error(error.response.data.msg, {
        style: {
          backgroundColor: '#1E9FD4',
          color: 'white'
        }
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    getTeam()
  }, [])

  return (
    <>
      <Box>
        <NavbarAdmin />
        <Container maxWidth='lg'>
          <Box sx={{ paddingTop: '100px' }}>
            <Box
              sx={{
                display: { md: 'flex' },
                justifyContent: { md: 'space-between' }
              }}
            >
              <Box
                sx={{
                  fontWeight: 600,
                  color: 'primary.main',
                  fontSize: '40px',
                  display: { xs: 'flex' },
                  justifyContent: { xs: 'center' }
                }}
              >
                Team
              </Box>
              <Box
                sx={{
                  display: { xs: 'flex' },
                  justifyContent: { xs: 'center' },
                  paddingTop: { xs: '20px' }
                }}
              >
                <Button
                  onClick={handleOpenModal}
                  borderRadius='0px'
                  text='Add New'
                />
              </Box>
            </Box>
            <Box
              sx={{
                paddingTop: '50px',
                paddingBottom: '30px'
              }}
            >
              <Divider />
            </Box>
            <Box
              sx={{
                paddingTop: { xs: '50px', md: '70px' }
              }}
            >
              {loading &&
                <LoadingImage />}
              {!loading && allTeam?.length < 1 &&
                <Box
                  sx={{
                    width: '100%',
                    height: '50vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  No Team member has been added yet
                </Box>}

              {!loading && allTeam?.length > 0 &&
                <Grid
                  container
                  justifyContent='center'
                  spacing={{ xs: 4, md: 12 }}
                  sx={{
                    paddingBottom: { xs: '300px', md: '50px' }
                  }}
                >
                  {allTeam.map(({ profileImg, firstName, lastName, role, _id }, index) => (
                    <Grid
                      key={index} item xs={12} md={4}
                    >
                      <Stack
                        spacing={1}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              alignSelf: 'start',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleEditOpenModal(index)}
                          >
                            <img alt='' width='30px' height='100%' src={editIcon} />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: '#F0F0F0',
                                borderRadius: '50%',
                                height: '200px',
                                width: '200px'
                              }}
                            >
                              <img alt='' src={profileImg} width='100%' height='100%' />
                            </Box>
                          </Box>
                          <Box
                            onClick={() => deleteTeamMember(_id)}
                            sx={{
                              alignSelf: 'start',
                              cursor: 'pointer'
                            }}
                          >
                            <img alt='' width='20px' height='100%' src={deleteIcon} />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Stack
                            direction='row'
                            spacing={1}
                            sx={{
                              fontWeight: '600px'
                            }}
                          >
                            <Box
                              sx={{
                                color: 'primary.main'
                              }}
                            >{firstName}
                            </Box>
                            <Box>{lastName}</Box>
                          </Stack>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#9F9F9F'
                          }}
                        >{role}
                        </Box>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>}
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Add Item */}
      <Dialog
        // fullWidth
        maxWidth={{ xs: 'sm' }}
        open={openModal}
        onClose={handleOpenModal}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '20px'
            }}
          >
            <Box>Add New</Box>
            <Box
              sx={{
                cursor: 'pointer'
              }}
              onClick={handleOpenModal}
            ><CloseIcon />
            </Box>
          </Box>

          <Box
            sx={{
              paddingTop: '30px',
              paddingBottom: '30px'
            }}
          >
            <Divider />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box
              sx={{
                paddingTop: { md: '40px' }
              }}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={adminAddStaffValidator}
                onSubmit={async (values, { isSubmitting, resetForm }) => {
                // setLoading(true)

                  try {
                    const { data } = await axios.post(
                  `${process.env.REACT_APP_PROD_MODE === 'test'
                    ? process.env.REACT_APP_API_URL_TEST
                    : process.env.REACT_APP_API_URL_LIVE}/admin/add-team-member`,
                  { ...values },
                  {
                    headers: {
                      'x-auth-token': token,
                      'Content-Type': 'application/json'
                    }
                  }
                    )

                    toast.success(data.message, {
                      style: {
                        backgroundColor: '#1E9FD4',
                        color: 'white'
                      }
                    })
                  } catch (error) {
                    toast.error(error.response.data.msg, {
                      style: {
                        backgroundColor: '#1E9FD4',
                        color: 'white'
                      }
                    })
                  }
                  // setLoading(false)
                  handleOpenModal()
                  getTeam()
                  resetForm()
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleBlur,
                  values,
                  setFieldTouched,
                  setFieldError
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      spacing={{ xs: 2, md: 4 }}
                      container
                    >
                      <Grid
                        item xs={12}
                      >
                        <Box
                          sx={{
                            display: { md: 'flex' }
                          }}
                        >
                          <Box
                            sx={{
                              height: '100%'
                            }}
                          >
                            <img alt='' width='100%' height='100%' src={values[PROFILE_IMG] || adminProfilePlaceholder} />
                          </Box>
                          <Box
                            sx={{
                              paddingTop: { xs: '20%', md: '10%' },
                              paddingBottom: '20%',
                              paddingLeft: { md: '40px' }
                            }}
                          >
                            <Box
                              sx={{
                                display: { xs: 'flex' },
                                justifyContent: { xs: 'center' },
                                alignSelf: { md: 'center' },
                                paddingTop: { md: '120px' }

                              }}
                            >
                              <FileUploader
                                onTypeError={(err) =>
                                  toast.error(err, {
                                    style: {
                                      backgroundColor: '#1E9FD4',
                                      color: 'white'
                                    }
                                  })}
                                handleChange={
                                  e => {
                                    setFieldTouched(PROFILE_IMG, true, false)

                                    const file = e

                                    const fileSize = file.size / 1024 / 1024

                                    if (fileSize > 2) {
                                      // e.target.value = ''

                                      toast.error('File size cannot be more than 2MB.', {
                                        style: {
                                          backgroundColor: '#1E9FD4',
                                          color: 'white'
                                        }
                                      })

                                      return setFieldError(PROFILE_IMG, 'File size cannot be more than 2MB.')
                                    }
                                    const reader = new window.FileReader()

                                    reader.readAsDataURL(file)

                                    reader.onload = () => {
                                      setFieldValue(PROFILE_IMG, reader.result)
                                    }

                                    reader.onerror = () => setFieldValue(PROFILE_IMG, '')
                                  }

                                }
                                name='file'
                                types={fileTypes}
                              >
                                <Button borderRadius='0px' text='Upload Image' />
                              </FileUploader>
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{
                          color: 'red',
                          paddingTop: '6px',
                          fontSize: '11px',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                        >
                          {errors[PROFILE_IMG]}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputField
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={FIRST_NAME_LABEL}
                          errors={errors[FIRST_NAME]}
                          name={FIRST_NAME}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputField
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={LAST_NAME_LABEL}
                          errors={errors[LAST_NAME]}
                          name={LAST_NAME}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputField
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={ROLE_LABEL}
                          errors={errors[ROLE]}
                          name={ROLE}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{ paddingTop: '20px' }}
                    >
                      <Button disabled={isSubmitting} type='submit' text={isSubmitting ? <CircularProgress /> : 'Save'} borderRadius='0px' />
                    </Box>
                  </form>
                )}
              </Formik>

            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {/* edit Item */}
      <Dialog
        // fullWidth
        maxWidth={{ xs: 'sm' }}
        open={openEditModal}
        onClose={handleEditOpenModal}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '20px'
            }}
          >
            <Box>Edit team Member</Box>
            <Box
              sx={{
                cursor: 'pointer'
              }}
              onClick={handleEditOpenModal}
            ><CloseIcon />
            </Box>
          </Box>

          <Box
            sx={{
              paddingTop: '30px',
              paddingBottom: '30px'
            }}
          >
            <Divider />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box
              sx={{
                paddingTop: { md: '40px' }
              }}
            >
              <Formik
                initialValues={initialValuesEditTeam}
                validationSchema={adminAddStaffValidator}
                onSubmit={async (values, { isSubmitting, resetForm }) => {
                  // setLoading(true)

                  try {
                    const { data } = await axios.put(
                  `${process.env.REACT_APP_PROD_MODE === 'test'
                    ? process.env.REACT_APP_API_URL_TEST
                    : process.env.REACT_APP_API_URL_LIVE}/admin/edit-team-member/${allTeam[editTeamIndex]?._id}`,
                  { ...values },
                  {
                    headers: {
                      'x-auth-token': token,
                      'Content-Type': 'application/json'
                    }
                  }
                    )

                    toast.success(data.message, {
                      style: {
                        backgroundColor: '#1E9FD4',
                        color: 'white'
                      }
                    })
                  } catch (error) {
                    toast.error(error.response.data.msg, {
                      style: {
                        backgroundColor: '#1E9FD4',
                        color: 'white'
                      }
                    })
                  }
                  // setLoading(false)
                  handleEditOpenModal()
                  // navigate(ADMIN_TEAM_ROUTE)
                  getTeam()
                  resetForm()
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleBlur,
                  values,
                  setFieldTouched,
                  setFieldError
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      spacing={2}
                      container
                    >
                      <Grid
                        item xs={12}
                      >
                        <Box
                          sx={{
                            display: { md: 'flex' }
                          }}
                        >
                          <Box
                            sx={{
                              height: '100%'
                            }}
                          >
                            <img alt='' width='100%' height='100%' src={values[PROFILE_IMG] || adminProfilePlaceholder} />
                          </Box>
                          <Box
                            sx={{
                              paddingTop: { xs: '20%', md: '10%' },
                              paddingBottom: '20%',
                              paddingLeft: { md: '40px' }
                            }}
                          >
                            <Box
                              sx={{
                                display: { xs: 'flex' },
                                justifyContent: { xs: 'center' }
                              }}
                            >
                              <FileUploader
                                multiple
                                uploadedImg={values[PROFILE_IMG]}
                                handleChange={
                                  e => {
                                    if (!e.length) {
                                      return setFieldValue(PROFILE_IMG, '')
                                    }

                                    setFieldTouched(PROFILE_IMG, true, false)

                                    const file = e[0]

                                    const fileSize = file.size / 1024 / 1024

                                    if (fileSize > 2) {
                                      e.target.value = ''

                                      return setFieldError(PROFILE_IMG, 'File size cannot be more than 2MB.')
                                    }
                                    const reader = new window.FileReader()

                                    reader.readAsDataURL(file)

                                    reader.onload = () => {
                                      setFieldValue(PROFILE_IMG, reader.result)
                                    }

                                    reader.onerror = () => setFieldValue(PROFILE_IMG, '')
                                  }

                                }
                                name='file'
                                types={fileTypes}
                              >
                                <Button borderRadius='0px' text='Upload Image' />
                              </FileUploader>
                            </Box>
                            <Box
                              sx={{
                                paddingTop: '10px'
                              }}
                            >
                              Drag & drop multiple files to upload
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputField
                          value={values[FIRST_NAME]}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={FIRST_NAME_LABEL}
                          errors={errors[FIRST_NAME]}
                          name={FIRST_NAME}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputField
                          value={values[LAST_NAME]}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={LAST_NAME_LABEL}
                          errors={errors[LAST_NAME]}
                          name={LAST_NAME}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputField
                          value={values[ROLE]}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={ROLE_LABEL}
                          errors={errors[ROLE]}
                          name={ROLE}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{ paddingTop: '20px' }}
                    >
                      <Button disabled={isSubmitting} type='submit' text={isSubmitting ? <CircularProgress /> : 'Save'} borderRadius='0px' />
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
