import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Image from 'mui-image'

export default function PortfolioItem ({ img, heading, subText }) {
  return (
    <Stack spacing={1}>
      <Box>
        <Image alt='' src={img} width='100%' height='100%' />
      </Box>
      <Box
        sx={{
          fontSize: '20px',
          fontWeight: 600
        }}
      >
        {heading}
      </Box>
      <Box
        sx={{
          fontSize: '16px',
          fontWeight: 400
        }}
      >
        {subText}
      </Box>
    </Stack>
  )
}
