import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useLocation, useNavigate } from 'react-router-dom'
import { Zoom } from 'react-awesome-reveal'

import {
  WORK_DETAILS_ROUTE,
  CONTACT_ROUTE
} from '../utils/routes'

export default function GoToContact () {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <Container
      sx={{ display: pathname === WORK_DETAILS_ROUTE || pathname === CONTACT_ROUTE || pathname.split('/').includes('admin') ? 'none' : 'block' }}
      maxWidth='lg'
    >
      <Box
        sx={{
          fontSize: '50px',
          paddingTop: { xs: '100px', md: '150px' }
        }}
      >
        <Box
          sx={{
            backgroundColor: '#F8F8F8',
            padding: { xs: '50px 0px 50px  0px', md: '100px 0px 100px  0px' }
          }}
        >
          <Zoom>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: { xs: '25px', md: '40px' },
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              Let’s talk about you
            </Box>
            <Box
              onClick={() => navigate(CONTACT_ROUTE)}
              sx={{
                fontWeight: 400,
                fontSize: { xs: '15px', md: '25px' },
                display: 'flex',
                justifyContent: 'center',
                color: 'primary.main',
                paddingTop: '15px',
                cursor: 'pointer'
              }}
            >
              Get in touch <ArrowForwardIcon sx={{
            // fontSize: '15px',
            // alignSelf: 'center',
              paddingLeft: '10px',
              paddingBottom: { xs: '4px', md: '0px' },
              paddingTop: { md: '10px' }
            }}
                           />
            </Box>
          </Zoom>
        </Box>
      </Box>
      <Box />
    </Container>
  )
}
