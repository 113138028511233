import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import axios from 'axios'
import toast from 'react-hot-toast'
import CircularProgress from '@mui/material/CircularProgress'
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import { Formik } from 'formik'
import { useState } from 'react'

import womanContact from '../assets/images/womanContact.png'

import contactFormValidator, {
  NAME,
  NAME_LABEL,
  EMAIL,
  EMAIL_LABEL,
  MESSAGE,
  MESSAGE_LABEL,
  SUBJECT,
  SUBJECT_LABEL,
  PHONE_NUM,
  PHONE_NUM_LABEL
} from '../validators/contactFormValidator'

const initialValues = {
  [NAME]: '',
  [EMAIL]: '',
  [MESSAGE]: '',
  [SUBJECT]: '',
  [PHONE_NUM]: ''
}

export default function Contact () {
  const [loading, setLoading] = useState(false)

  return (
    <Container
      sx={{
        paddingTop: { xs: '100px', md: '120px' }
      }}
      maxWidth='lg'
    >
      <Box>
        <Box
          sx={{
            fontWeight: 600,
            fontSize: { xs: '30px', md: '50px' },
            color: 'primary.main',
            textAlign: { xs: 'center', md: 'left' },
            width: { md: '50%' },
            lineHeight: { md: '60px' }
          }}
        >
          Yes, we are <br /> already to help
        </Box>
        <Box
          sx={{
            paddingTop: { xs: '20px' },
            paddingBottom: { md: '20px' },
            fontWeight: 600,
            fontSize: { xs: '20px', md: '40px' },
            textAlign: { xs: 'center', md: 'left' },
            width: { md: '50%' },
            lineHeight: { md: '60px' }
          }}
        >
          Contact Us
        </Box>
        <Box
          sx={{
            border: { md: '1px solid #DFDFDF' },
            paddingTop: { xs: '40px', md: '20px' },
            display: { md: 'flex' },
            justifyContent: { md: 'space-between' }
          }}
        >
          <Box
            sx={{
              paddingLeft: { md: '50px' },
              paddingBottom: { md: '100px' }
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={contactFormValidator}
              onSubmit={async (values, { isSubmitting, resetForm }) => {
                setLoading(true)

                try {
                  const { data } = await axios.post(
                  `${process.env.REACT_APP_PROD_MODE === 'test'
                    ? process.env.REACT_APP_API_URL_TEST
                    : process.env.REACT_APP_API_URL_LIVE}/contact`,
                  { ...values }
                  )

                  toast.success(data.message)
                } catch (error) {
                  toast.error(error.response.data.msg)
                }
                setLoading(false)
                resetForm()
              }}
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      display: { md: 'flex' },
                      justifyContent: { md: 'space-between' }
                    }}
                  >
                    <Box>
                      <Box sx={{
                        paddingTop: { md: '30px' },
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'flex-start' }
                      }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: { xs: '100%', md: 500 }
                          }}
                        >
                          <TextField
                            style={{ background: 'white' }}
                            name={NAME}
                            fullWidth
                            error={errors[NAME] !== undefined}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='Name'
                            label={NAME_LABEL}
                            variant='standard'
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[NAME]}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: { xs: 'center', md: 'flex-start' },
                          paddingTop: { xs: '10px', md: '30px' }
                        }}
                      >
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: { xs: '100%' }
                        }}
                        >
                          <TextField
                            style={{ background: 'white' }}
                            name={EMAIL}
                            error={errors[EMAIL] !== undefined}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='Email'
                            label={EMAIL_LABEL}
                            variant='standard'
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[EMAIL]}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: { xs: 'center', md: 'flex-start' },
                          paddingTop: { xs: '10px', md: '30px' }
                        }}
                      >
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: { xs: '100%' }
                        }}
                        >
                          <TextField
                            style={{ background: 'white' }}
                            name={PHONE_NUM}
                            error={errors[PHONE_NUM] !== undefined}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='Email'
                            label={PHONE_NUM_LABEL}
                            variant='standard'
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[PHONE_NUM]}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: { xs: 'center', md: 'flex-start' },
                          paddingTop: { xs: '20px', md: '30px' }
                        }}
                      >
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%'
                        }}
                        >
                          <TextField
                            style={{ background: 'white' }}
                            name={SUBJECT}
                            error={errors[SUBJECT] !== undefined}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='SUBJECT'
                            label={SUBJECT_LABEL}
                            variant='standard'
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[SUBJECT]}
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'flex-start' },
                        paddingTop: '20px',
                        width: '100%'
                      }}
                      >
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%'
                        }}
                        >
                          <TextField
                            style={{
                              background: 'white'
                            }}
                            name={MESSAGE}
                            error={errors[MESSAGE] !== undefined}
                            fullWidth
                            minRows={10}
                            multiline
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='Write your message'
                            label={MESSAGE_LABEL}
                            variant='standard'
                          />
                          <Box sx={{
                            color: 'red',
                            paddingTop: '6px',
                            fontSize: '11px'
                          }}
                          >{errors[MESSAGE]}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          paddingTop: '20px',
                          display: 'flex',
                          justifyContent: { xs: 'center', md: 'flex-start' }
                        }}
                      >
                        <Button
                          sx={{
                            borderRadius: '0px',
                            backgroundColor: 'primary.main'
                          }}
                          disableElevation
                          disabled={loading}
                          type='submit'
                          variant='contained'
                        >
                          {loading ? <CircularProgress /> : 'Send Message'}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          <Box
            sx={{
              width: { md: '40%' },
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Box>
              <img alt='' src={womanContact} width='100%' height='100%' />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <Box
              sx={{
                paddingTop: '150px',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingBottom: '50px',
                fontSize: { xs: '15px', md: '20px' }
              }}
            >
              <Box>
                Our offices
              </Box>
              <Box
                sx={{
                  display: 'flex'
                }}
              >
                <Stack
                  sx={{
                    paddingTop: '20px'
                  }}
                >
                  <Box>Nigeria: </Box>
                  <Box
                    sx={{ paddingTop: '40px' }}
                  >UK:
                  </Box>
                  <Box
                    sx={{ paddingTop: '40px' }}
                  >Kenya:
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    paddingTop: '20px',
                    paddingLeft: '30px'
                  }}
                >
                  <Box
                    component='a'
                    target='_blank'
                    href='https://goo.gl/maps/D6NzjaqQQLqmjEoS9'
                    rel='noreferrer'
                    sx={{ paddingBottom: '10px' }}
                  >14A Oba Elegushi Road,<br /> Ikoyi, Lagos State.
                  </Box>
                  <Box
                    component='a'
                    target='_blank'
                    href='https://goo.gl/maps/HK5Upz7sWFyNMnDN7'
                    rel='noreferrer'
                    sx={{ paddingBottom: '10px' }}
                  >25, Dalmeyer Road London,<br /> NW10 2XA, London.
                  </Box>
                  <Box
                    component='a'
                    target='_blank'
                    href='https://goo.gl/maps/H9axZ6Cofck1NKPB8'
                    rel='noreferrer'
                  >7th Floor, Muthangari Drive<br /> off Waiyaki Way, Westlands<br /> Nairobi.
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
