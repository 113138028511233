import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import { useNavigate } from 'react-router-dom'

import whiteLogo from '../../assets/images/whiteLogo.svg'
import whiteCancelIcon from '../../assets/icons/whiteCancelIcon.svg'
import instaIcon from '../../assets/icons/instaIcon.svg'
import twitterIcon from '../../assets/icons/twitterIcon.svg'
import linkedinIcon from '../../assets/icons/linkedinIcon.svg'
import faceIcon from '../../assets/icons/faceIcon.svg'
import { HOME_ROUTE, WORK_ROUTE, SERVICES_ROUTE, ABOUT_ROUTE, CONTACT_ROUTE } from '../../utils/routes'

const navItems = [{ text: 'Home', link: HOME_ROUTE }, { text: 'Work', link: WORK_ROUTE }, { text: 'Services', link: SERVICES_ROUTE }, { text: 'About', link: ABOUT_ROUTE }, { text: 'Contact', link: CONTACT_ROUTE }
]

const socialsIcons = [{ icon: instaIcon, link: 'https://www.instagram.com/ionec_ltd/' }, { icon: linkedinIcon, link: 'https://www.linkedin.com/in/ionec/' }, { icon: faceIcon, link: 'https://web.facebook.com/Innovate1company?_rdc=1&_rdr' }]

export default function DesktopNavbar ({
  handleOpenDrawer,
  openDrawer,
  setOpenDrawer
}) {
  const navigate = useNavigate()

  return (
    <Box>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: 'primary.main',
            color: 'white'
          }
        }}
        sx={{
          background: 'primary.main',
          display: { xs: 'none', md: 'flex' }
        }}
        anchor='top'
        open={openDrawer}
        onClose={handleOpenDrawer}
      >
        <Container
          sx={{
            paddingBottom: '200px'
          }}
          maxWidth='lg'
        >
          <Box
            sx={{
              paddingTop: '20px',
              paddingBottom: '50px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box
              onClick={() => navigate('/')}
              sx={{
                cursor: 'pointer'
              }}
            >
              <img alt='' src={whiteLogo} width='90%' height='100%' />
            </Box>
            <Box
              onClick={handleOpenDrawer}
              sx={{
                width: '4%',
                cursor: 'pointer'
              }}
            >
              <img alt='' src={whiteCancelIcon} width='100%' height='100%' />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignSelf: 'center'
            }}
          >
            <Box>
              <Box
                sx={{
                  paddingTop: '150px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingBottom: '50px',
                  fontSize: '20px'
                }}
              >
                <Box>
                  Our offices
                </Box>
                <Box
                  sx={{
                    display: 'flex'
                  }}
                >
                  <Stack
                    sx={{
                      paddingTop: '20px'
                    }}
                  >
                    <Box>Nigeria: </Box>
                    <Box
                      sx={{ paddingTop: '40px' }}
                    >UK:
                    </Box>
                    <Box
                      sx={{ paddingTop: '40px' }}
                    >Kenya:
                    </Box>
                  </Stack>
                  <Stack
                    sx={{
                      paddingTop: '20px',
                      paddingLeft: '30px'
                    }}
                  >
                    <Box
                      component='a'
                      target='_blank'
                      href='https://goo.gl/maps/D6NzjaqQQLqmjEoS9'
                      rel='noreferrer'
                      sx={{ paddingBottom: '10px' }}
                    >14A Oba Elegushi Road,<br /> Ikoyi, Lagos State.
                    </Box>
                    <Box
                      component='a'
                      target='_blank'
                      href='https://goo.gl/maps/HK5Upz7sWFyNMnDN7'
                      rel='noreferrer'
                      sx={{ paddingBottom: '10px' }}
                    >25, Dalmeyer Road London,<br /> NW10 2XA, London.
                    </Box>
                    <Box
                      component='a'
                      target='_blank'
                      href='https://goo.gl/maps/H9axZ6Cofck1NKPB8'
                      rel='noreferrer'
                    >7th Floor, Muthangari Drive<br /> off Waiyaki Way, Westlands<br /> Nairobi.
                    </Box>
                  </Stack>
                </Box>
              </Box>
              <Box sx={{
                paddingTop: '30px',
                paddingLeft: '10px',
                paddingRight: '10px'
              }}
              >
                Connect with us on
              </Box>
              <Box
                sx={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingTop: '10px'
                }}
              >
                <Stack spacing={2} direction='row'>
                  {socialsIcons.map(({ icon, link }, index) =>
                    <Box component='a' href={link} target='__blank' key={index}>
                      <img alt='' src={icon} width='100%' height='100%' />
                    </Box>
                  )}
                </Stack>
              </Box>
            </Box>
            <Box>
              <Box>
                {navItems.map(({ text, link }, index) => (
                  <ListItemButton
                    sx={{
                      fontWeight: 'bolder',
                      fontSize: '75px',
                      align: 'right'
                    }}
                    onClick={() => {
                      handleOpenDrawer()
                      navigate(link)
                    }}
                    key={index}
                  >
                    {text}
                  </ListItemButton>
                ))}
              </Box>
            </Box>
          </Box>
        </Container>
      </Drawer>
    </Box>
  )
}
