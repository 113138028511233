import Box from '@mui/material/Box'
import NavbarAdmin from './NavbarAdmin'
import Container from '@mui/material/Container'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import { useState, useEffect } from 'react'

import Button from '../Button'
import Table from './Table'
import { ADMIN_WORK_ADD_ROUTE, ADMIN_LOGIN_ROUTE } from '../../utils/routes'

const token = localStorage.getItem('adminIONECToken')

export default function WorkAdmin () {
  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem('adminIONEC') === undefined ||
      localStorage.getItem('adminIONECToken') === undefined ||
    localStorage.getItem('adminIONEC') === null || localStorage.getItem('adminIONECToken') === null
    ) {
      navigate(ADMIN_LOGIN_ROUTE)
    }
  }, [navigate])

  return (
    <Box>
      <NavbarAdmin />
      <Container maxWidth='lg'>
        <Box sx={{ paddingTop: '100px' }}>
          <Box sx={{
            display: { md: 'flex' },
            justifyContent: { md: 'space-between' },
            paddingTop: { md: '30px' }
          }}

          >
            <Box
              sx={{
                fontWeight: 600,
                color: 'primary.main',
                fontSize: '40px',
                display: { xs: 'flex' },
                justifyContent: { xs: 'center' }
              }}
            >
              Work
            </Box>
            <Box
              sx={{
                display: { xs: 'flex' },
                justifyContent: { xs: 'center' },
                paddingTop: { xs: '20px' }
              }}
            >
              <Button onClick={() => navigate(ADMIN_WORK_ADD_ROUTE)} text='Add New' borderRadius='0px' />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: '50px',
            paddingBottom: '100px'
          }}
        >
          <Table />
        </Box>
      </Container>
    </Box>
  )
}
