import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { useNavigate, useLocation } from 'react-router-dom'

import Divider from '@mui/material/Divider'
import { useState } from 'react'

import logo from '../../assets/images/logo.svg'
import circleIcon from '../../assets/icons/circleIcon.svg'
import { ADMIN_WORK_ROUTE, ADMIN_LOGIN_ROUTE, ADMIN_TEAM_ROUTE } from '../../utils/routes'

export default function NavbarAdmin () {
  const [openMenu, setOpenMenu] = useState(null)
  const { pathname } = useLocation()
  const open = Boolean(openMenu)
  const navigate = useNavigate()

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  }
  const handleClose = () => {
    setOpenMenu(null)
  }

  return (
    <Box>
      <AppBar
        sx={{
          backgroundColor: 'white',
          color: 'black',
          position: 'fixed',
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
        elevation={0}
      >
        <Toolbar>
          <Container
            sx={{ display: 'flex' }}
            maxWidth='lg'
          >
            <Box
              sx={{
                width: { xs: '5%' },
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' }
              }}
            >
              <img src={logo} alt='' width='40%' height='100%' />
            </Box>
            <Box
              sx={{
                width: { xs: '5%' },
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' }
              }}
            >
              <img src={logo} alt='' width='20%' height='100%' />
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                width: 'fit-content',
                // border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                bgcolor: 'background.paper',
                color: 'black',
                '& svg': {
                  m: 1.5
                },
                '& hr': {
                  mx: 0.5
                }
              }}
            >
              <Stack
                direction='row'
                spacing={2}
              >
                <Box
                  onClick={() => navigate(ADMIN_WORK_ROUTE)}
                  sx={{
                    borderBottom: pathname.split('/').includes('work') ? '4px solid #1E9FD4' : 'none',
                    cursor: 'pointer'
                  }}
                >
                  Work
                </Box>
                <Box
                  onClick={() => navigate(ADMIN_TEAM_ROUTE)}
                  sx={{
                    borderBottom: pathname.split('/').includes('team') ? '4px solid #1E9FD4' : 'none',
                    cursor: 'pointer'
                  }}
                >
                  Team
                </Box>
              </Stack>
              <Divider
                sx={{
                  paddingRight: { md: '50px' }
                }}
                orientation='vertical' variant='middle' flexItem
              />
              <Box
                onClick={() => {
                  localStorage.removeItem('adminIONEC')
                  localStorage.removeItem('adminIONECToken')
                  navigate(ADMIN_LOGIN_ROUTE)
                }}
                sx={{
                  cursor: 'pointer',
                  paddingLeft: { md: '20px' }
                }}
              >
                Log out
              </Box>
            </Box>
            <Box
              onClick={handleOpenMenu}
              sx={{
                width: { xs: '10%', md: '5%' },
                cursor: 'pointer',
                display: { xs: 'flex', md: 'none' }
              }}
            >
              <img src={circleIcon} alt='' width='100%' height='100%' />
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={openMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            navigate(ADMIN_WORK_ROUTE)
          }}
        >Work
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            navigate(ADMIN_TEAM_ROUTE)
          }}
        >Team
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          localStorage.removeItem('adminIONEC')
          localStorage.removeItem('adminIONECToken')
          navigate(ADMIN_LOGIN_ROUTE)
        }}
        >Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}
