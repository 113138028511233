import Box from '@mui/material/Box'

import logo from '../assets/icons/logoIcon.svg'

export default function LoadingImage () {
  return (
    <Box className='loader'>
      <img className='logo' alt='' width='10%' height='100%' src={logo} />
    </Box>
  )
}
