import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

export default function InputField ({ icon, label, width, topHidden, errors, name, ...props }) {
  return (
    <Box
      sx={{
        width
      }}
    >
      <Stack
        direction='row'
        spacing={2}
        sx={{
          fontSize: { xs: '14px' },
          paddingBottom: '12px',
          paddingLeft: '5px',
          display: topHidden ? 'none' : 'flex'
        }}
      ><Box><img alt='' src={icon} width='100%' height='100%' /> </Box>
        <Box
          sx={{
            alignSelf: 'center',
            fontWeight: 600,
            fontS: '15px'
          }}
        >{label}
        </Box>
      </Stack>
      <TextField
        fullWidth
        sx={{
          '& .MuiFilledInput-root': {
            backgroundColor: '#EDF2F7',
            border: '0px',
            borderRadius: '5px',
            width: '100%'
          }
        }}
        name={name}
        InputProps={{ disableUnderline: true }}
        variant='filled'
        {...props}
      />
      <Box sx={{
        color: 'red',
        paddingTop: '6px',
        fontSize: '11px'
      }}
      >{errors}
      </Box>
    </Box>
  )
}
