import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { useState } from 'react'

import briefcase from '../assets/images/briefcase.svg'

const whatWedo = ['- Business Consulting',
  '- Brand Identity', '- UI/UX Design',
  '- Social Media Managment',
  '- Software Development']

const whatWeCards = [{

  num: 'One',
  heading:
  <Box
    sx={{
      fontWeight: 600,
      fontSize: '22px'
    }}
  >
    Business <br />
    Consulting
  </Box>,
  subText: 'We balance your budget and affordability with an effective user experience or effective campaign to reach your audience',
  backgroundColor: 'primary.main',
  borderTop: '8px solid black',
  numColor: 'white',
  headingColor: 'white',
  subTextColor: 'white'
},
{
  num: 'Two',
  heading:
  <Box
    sx={{
      fontWeight: 600,
      fontSize: '22px'
    }}
  >
    We Connect <br /> Clients
  </Box>,
  subText: 'We love connecting our clients to benefit from each other’s audiences and resources. This is one unique way we increase our clients’ successes annually.',
  backgroundColor: 'black',
  borderTop: '8px solid #1E9FD4',
  numColor: 'white',
  headingColor: 'white',
  subTextColor: 'white'
},
{
  num: 'Three',
  heading:
  <Box
    sx={{
      fontWeight: 600,
      fontSize: '22px'
    }}
  >
    Premium <br />
    Support
  </Box>,
  subText: 'Once you become a client, we are at your service through phone calls, web calls, 24/7 email support, online chat, mobile chat and more ways to stay in touch to serve you.',
  backgroundColor: '#EDEDED',
  borderTop: '8px solid black',
  numColor: '#262525',
  headingColor: 'primary.main',
  subTextColor: '#262525'
}

]

export default function Services () {
  const [selIndex, setSelIndex] = useState(0)

  return (
    <Container
      sx={{
        paddingTop: { xs: '100px', md: '120px' },
        minHeight: '100vh'
      }}
      maxWidth='lg'
    >
      <Box
        sx={{
          fontWeight: 600,
          fontSize: { xs: '30px', md: '50px' },
          color: 'primary.main',
          textAlign: { xs: 'center', md: 'left' },
          width: { md: '50%' }
        }}
      >
        We help organisations
        transform their impact
      </Box>
      <Box
        sx={{
          display: { md: 'flex' },
          justifyContent: { md: 'space-between' },
          paddingTop: { md: '70px' }
        }}
      >
        <Box>
          <Box
            sx={{
              paddingTop: '30px',
              fontWeight: 600,
              fontSize: { xs: '30px', md: '50px' },
              textAlign: { xs: 'center', md: 'left' }
            //   width: { md: '100%' }
            }}
          >
            What we do
          </Box>
          <Box
            sx={{
              paddingTop: '20px'
            }}
          >
            <Grid spacing={2} justifyContent='center' container>
              {whatWedo.map((item, index) => (
                <Grid
                  sx={{
                    cursor: 'pointer',
                    fontWeight: 600,
                    color: index === selIndex ? 'primary.main' : '#9F9F9F',
                    fontSize: '24px'
                  }}
                  onClick={() => setSelIndex(index)}
                  key={index} item xs={12}
                >
                  {item}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              paddingTop: '30px'
            }}
          >
            <Box
              sx={{
                backgroundColor: 'primary.main',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: { xs: '350px', md: '400px' },
                color: 'white',
                width: { md: '700px' }
              }}
            >
              <Stack
                spacing={3}
                padding={{ xs: '10px', md: '30px' }}
              >
                <Box
                  sx={{
                    width: { xs: '15%', md: '8%' }
                  }}
                >
                  <img alt='' src={briefcase} width='100%' height='100%' />
                </Box>
                <Box
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: '22px', md: '40px' },
                    lineHeight: { md: '50px' }
                  }}
                >
                  Business <br />
                  Consulting
                </Box>
                <Box
                  sx={{
                    fontSize: { xs: '13px', md: '18px' }
                  }}
                >
                  Some businesses have the infrastructure but lack the human
                  resource for Project Implementation and execution. We have skilled
                  and proficient Project Managers who aggressively and strategically
                  “Mid-Wife” and nurture projects through infancy. By creating
                  project structures, setting clear goals, surgical risk assessment and
                  recognition, project success is a given.
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            paddingTop: '90px',
            fontWeight: 600,
            fontSize: { xs: '30px', md: '50px' },
            textAlign: { xs: 'center', md: 'left' },
            width: { md: '50%' }
          }}
        >
          Our Approach
        </Box>
        <Box
          sx={{ paddingTop: '30px' }}
        >
          <Stack
            spacing={2}
            direction={{ md: 'row' }}
            justifyContent='space-between'
          >
            {
              whatWeCards.map(({
                num, heading, subText,
                backgroundColor, borderTop,
                numColor, headingColor, subTextColor
              }, index) => (
                <Box
                  key={index}
                  sx={{
                    paddingTop: { xs: '20px', md: '0px' }
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      height: '300px',
                      color: ' white',
                      borderTop,
                      padding: '20px',
                      width: { md: '350px' }
                    }}
                  >
                    <Stack
                      spacing={2}
                    >
                      <Box
                        sx={{
                          color: numColor
                        }}
                      >
                        {num}
                      </Box>
                      <Box
                        sx={{
                          fontWeight: 600,
                          fontSize: '30px',
                          color: headingColor
                        }}
                      >
                        {heading}
                      </Box>
                      <Box
                        sx={{ color: subTextColor }}
                      >
                        {subText}
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              ))
        }
          </Stack>
        </Box>
      </Box>
    </Container>
  )
}
