import GraphikFont from '../assets/font/Graphik-Regular-Web.woff2'
import GraphikFontBold from '../assets/font/Graphik-Bold.woff2'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: 'Graphik, Arial, GraphikBold'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Graphik';
              font-style: normal;
              font-display: swap;
              src: local('Graphik'), local('Graphik-Regular-Web.woff2'), url(${GraphikFont}) format('woff2');
            },@font-face {
              font-family: 'GraphikBold';
              font-style: Bold;
              font-display: swap;
              src: local('GraphikBold'), local('Graphik-Bold.woff2'), url(${GraphikFontBold}) format('woff2');
            } 
          `

    }
  },
  palette: {
    primary: {
      main: '#1E9FD4'
    },
    secondary: {
      main: '#F69220',
      subMain: '#EA088B'
    }
  }
})
