import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import d4uPort from '../../assets/images/d4uPort.png'
import lidPort from '../../assets/images/lidPort.png'
import fix234Port from '../../assets/images/fix234Port.png'
import PortfolioItem from '../PortfolioItem'
import { WORK_DETAILS_ROUTE } from '../../utils/routes'
import LoadingImage from '../Loading'

export default function WorkItems () {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [works, setWorks] = useState([])

  const getWorks = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PROD_MODE === 'test'
          ? process.env.REACT_APP_API_URL_TEST
          : process.env.REACT_APP_API_URL_LIVE}/admin/all-works`)

      setWorks(data.works.filter((work) => work.publish))

      // toast.success(data.message, {
      //   style: {
      //     backgroundColor: '#1E9FD4',
      //     color: 'white'
      //   }
      // })
    } catch (error) {
      // toast.error(error.response.data.message, {
      //   style: {
      //     backgroundColor: '#1E9FD4',
      //     color: 'white'
      //   }
      // })
    }
    setLoading(false)
  }

  useEffect(() => {
    getWorks()
  }, [])

  return (
    <Container
      sx={{
        paddingTop: { xs: '100px', md: '120px' }
      }}
      maxWidth='lg'
    >
      <Box>
        <Box
          sx={{
            fontWeight: 600,
            fontSize: { xs: '30px', md: '50px' },
            color: 'primary.main',
            textAlign: { xs: 'center', md: 'left' },
            width: { md: '50%' }
          }}
        >
          We help brands deliver on their promises.
        </Box>
        <Box
          sx={{ paddingTop: '50px' }}
        >

          {loading &&
            <LoadingImage />}
          {!loading && works?.length < 1 &&
            <Box
              sx={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              No Work has been added yet
            </Box>}

          {!loading && works.length > 0 &&
            <Grid justifyContent='center' spacing={6} container>
              {works.map(({ smallThumbnail, projectName, tagline, slug }, index) => (
                <Grid
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/work/${slug}`)}
                  key={index}
                  item
                  xs={12}
                  md={6}
                >
                  <PortfolioItem img={smallThumbnail} heading={projectName} subText={tagline} />
                </Grid>
              ))}
            </Grid>}

        </Box>
      </Box>
    </Container>
  )
}
