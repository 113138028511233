import Button from '@mui/material/Button'

export default function ButtonComponent ({ text, width, borderRadius, backgroundColor, color, ...props }) {
  return (
    <Button
      disableElevation
      sx={{
        textTransform: 'none',
        width,
        borderRadius,
        color,
        backgroundColor
      }}
      variant='contained'
      {...props}
    >{text}
    </Button>
  )
}
