import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import axios from 'axios'
import toast from 'react-hot-toast'
import CircularProgress from '@mui/material/CircularProgress'
import { HiArrowSmRight } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { Fade, Bounce } from 'react-awesome-reveal'
import { useState, useEffect } from 'react'

import d4uPort from '../../assets/images/d4uPort.png'
import lidPort from '../../assets/images/lidPort.png'
import fix234Port from '../../assets/images/fix234Port.png'
import PortfolioItem from '../PortfolioItem'

import { WORK_DETAILS_ROUTE, WORK_ROUTE } from '../../utils/routes'

const portItems = [
  {
    img: fix234Port,
    heading: 'Fix234',
    subText: 'Revitalizing facility management',
    link: WORK_DETAILS_ROUTE
  },
  {
    img: lidPort,
    heading: 'Lidstores',
    subText: 'Branding, UIUX, Web Development, Marketing'
  },
  {
    img: d4uPort,
    heading: 'D4U Logistics',
    subText: 'Branding, UI/UX, Web Development, Marketing'
  }
]

export default function PortfolioItems () {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [works, setWorks] = useState([])

  const getWorks = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PROD_MODE === 'test'
          ? process.env.REACT_APP_API_URL_TEST
          : process.env.REACT_APP_API_URL_LIVE}/admin/all-works`)

      setWorks(data.works.filter((work) => work.publish))

      // toast.success(data.message, {
      //   style: {
      //     backgroundColor: '#1E9FD4',
      //     color: 'white'
      //   }
      // })
    } catch (error) {
      // toast.error(error.response.data.message, {
      //   style: {
      //     backgroundColor: '#1E9FD4',
      //     color: 'white'
      //   }
      // })
    }
    setLoading(false)
  }

  useEffect(() => {
    getWorks()
  }, [])

  return (
    <Container maxWidth='lg'>
      <Box
        sx={{
          paddingTop: { xs: '30px', md: '60px' }
        }}
      >
        <Fade>
          <Box
            sx={{
              fontSize: { xs: '25px', md: '48px' },
              fontWeight: 600,
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-start' },
              paddingBottom: '40px'
            }}
          >
            Some of our recent work
          </Box>
        </Fade>
        <Box>
          <Box>

            {!loading && works?.length < 1 &&
              <Box
                sx={{
                  width: '100%',
                  height: '50vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                No Work has been added yet
              </Box>}

            {loading &&
              <Box
                sx={{
                  minHeight: '30vh',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '30px'
                }}
              >
                <CircularProgress />
              </Box>}
            {!loading && works.length > 0 &&
              <Grid justifyContent='center' spacing={6} container>
                {works.map(({ smallThumbnail, projectName, tagline, slug }, index) => (
                  <Grid
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/work/${slug}`)}
                    key={index}
                    item
                    xs={12}
                    md={6}
                  >
                    <PortfolioItem img={smallThumbnail} heading={projectName} subText={tagline} />
                  </Grid>
                ))}
              </Grid>}
            <Box
              onClick={() => navigate(WORK_ROUTE)}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '20px',
                color: 'primary.main'
              }}
            >
              <Box sx={{

                fontSize: '20px'
              }}
              > View more works
              </Box>
              <Box
                sx={{
                  alignSelf: 'center',
                  height: '100%',
                  paddingTop: '4px',
                  fill: 'primary.main'
                }}
              ><HiArrowSmRight />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
