import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { useNavigate, useLocation } from 'react-router-dom'

import heroTextImage from '../assets/images/heroTextImage.svg'
import logo from '../assets/images/logo.svg'
import instagramIconFooter from '../assets/icons/instagramIconFooter.svg'
import twitterIconFooter from '../assets/icons/twitterIconFooter.svg'
import linkedinIconFooter from '../assets/icons/linkedinIconFooter.svg'
import facebookIconFooter from '../assets/icons/facebookIconFooter.svg'

import { HOME_ROUTE, WORK_ROUTE, SERVICES_ROUTE, ABOUT_ROUTE, CONTACT_ROUTE } from '../utils/routes'

const socialIcons = [{ icon: instagramIconFooter, link: 'https://www.instagram.com/ionec_ltd/' }, { icon: linkedinIconFooter, link: 'https://www.linkedin.com/in/ionec/' }, { icon: facebookIconFooter, link: 'https://web.facebook.com/Innovate1company?_rdc=1&_rdr' }]

const footerLinks = [{ text: 'Home', link: HOME_ROUTE }, { text: 'Work', link: WORK_ROUTE }, { text: 'Services', link: SERVICES_ROUTE }, { text: 'About', link: ABOUT_ROUTE }, { text: 'Contact Us', link: CONTACT_ROUTE }]

const footerContactLink = [{ text: 'info@ionec.com', link: 'mailto:info@ionec.com' }, { text: '+234 903 582 2688', link: 'tel:+234 903 582 2688' }]

const date = new Date().getFullYear()

export default function Footer () {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Box
      sx={{
        backgroundImage: `url(${heroTextImage})`,
        minHeight: '200px',
        display: pathname.split('/').includes('admin') ? 'none' : 'block'
      }}
    >
      <Container maxWidth='lg'>
        <Box sx={{
          paddingTop: '30px',
          display: { md: 'flex' },
          justifyContent: 'space-between'
        }}
        >
          <Stack spacing={4}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-start' },
                width: '100%'
              }}
            >
              <img src={logo} alt='' width='70%' height='100%' />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-start' },
                fontWeight: 600,
                fontSize: { md: '16px' }
              }}
            >
              We innovate your business
            </Box>
            <Box>
              <Stack
                direction='row'
                spacing={2}
                justifyContent={{ xs: 'center', md: 'flex-start' }}
              >
                {socialIcons.map(({ icon, link }, index) => (
                  <Box
                    component='a' href={link} target='__blank'
                    key={index}
                    sx={{ width: { xs: '10%', md: '40px' } }}
                  >
                    <img alt='' src={icon} width='90%' height='100%' />
                  </Box>
                ))}
              </Stack>
            </Box>
          </Stack>
          <Box
            sx={{
              paddingTop: { xs: '50px', md: '0px' },
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Stack
              spacing={1}
            >
              {footerLinks.map(({ text, link }, index) => (
                <Box
                  onClick={() => navigate(link)}
                  sx={{
                    textAlign: { xs: 'center', md: 'left' },
                    fontWeight: 400,
                    fontSize: '16px',
                    cursor: 'pointer'
                  }}
                  key={index}
                >{text}
                </Box>
              ))}
            </Stack>
          </Box>
          <Box
            sx={{
              paddingTop: { xs: '50px', md: '0px' },
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 400
            }}
          >
            <Stack
              spacing={1}
            >
              <Box>For general enquiries</Box>
              {footerContactLink.map(({ text, link }, index) => (
                <Box
                  component='a' href={link}
                  sx={{
                    textAlign: { xs: 'center', md: 'left' },
                    fontWeight: 600,
                    fontSize: '16px'
                  }}
                  key={index}
                >{text}
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: { xs: '15px', md: '30px' },
            paddingBottom: { xs: '15px', md: '30px' },
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' },
            fontSize: { xs: '12px', md: '16px' }
          }}
        >{`© ${date} Innovate One Company. All Rights Reserved.`}
        </Box>
      </Container>
    </Box>

  )
}
