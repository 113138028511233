export const HOME_ROUTE = '/'
export const WORK_ROUTE = '/work'
export const WORK_DETAILS_ROUTE = '/work/:slug'
export const SERVICES_ROUTE = '/services'
export const ABOUT_ROUTE = '/about'
export const CONTACT_ROUTE = '/contact'
export const ADMIN_ROUTE = '/admin'
export const ADMIN_LOGIN_ROUTE = '/admin/login'
export const ADMIN_WORK_ROUTE = '/admin/work'
export const ADMIN_WORK_ADD_ROUTE = '/admin/work/add'
export const ADMIN_WORK_EDIT_ROUTE = '/admin/work/edit/:slug'
export const ADMIN_TEAM_ADD_ROUTE = '/admin/team/add'
export const ADMIN_TEAM_ROUTE = '/admin/team'
